.page.download {

	
	#hero {
		height: 200px !important;
		min-height: 200px !important;
	}

	.hidden {
		display: none;
	}

	.download-buttons {
		text-align: center;
		margin-top: 50px;
		img {
			max-width: 240px;
		}

		a {
			margin:0 !important;
		}

		.apple {

			img {
				max-width: 210px;
			}
		}
	}
	
}