.page.route-collection {
    .section-background {
        background: transparent;
    }
    .slice-element {
        z-index: 10;
    }
    .carousel-background:hover {
        .slick-next {
            transform: translate(0px, 0);
            opacity: 1;
        }
    }
    .carousel-background {
        outline: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .slick-prev {
            display: none !important;
        }
    }

    .form-btn-container {
        text-align: center;
        width: 100%;
    }

    .btn-form {
        background: dodgerblue;
        border-radius: 5px;
        padding: 7px;
        padding-left: 25px;
        color: #fff;
        padding-right: 25px;
        transition: all 0.4s ease;
        border: 0px solid #000;
        font-family: $montserrat;
        font-size: 12pt;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .btn-form:hover {
        box-shadow: 0 0 10px #fff;
        cursor: pointer;
    }

    .slick-next {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        transition: all 0.3s ease;
        position: absolute;
        opacity: 0;
        right: 0;
        transform: translate(20px, 0);
        z-index: 1;
        width: 10%;
        font-family: $montserrat;
        color: #fff;
        cursor: pointer;
        min-width: 60px;
        top: 0;
        height: 100%;
        border: none;
        outline: none;
        content: "";
        color: transparent;
    }

    .slick-next:before {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        content: " ";
        background-size: 40px auto;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/icons/icon-right-white.png);
    }

    .carousel-slide-bg {
        top: 0;
        outline: none;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    #hero {
        min-height: 500px;
        height: 68%;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }
    .slick-track .slick-slide {
        height: 100%;
    }

    .mapouter {
        width: 100%;
    }

    .gmap_canvas {
        width: 100% !important;

        iframe {
            width: 100%;
        }
    }

    .body-container {
        .text-content {
            text-align: justify !important;
        }

        p {
            text-align: justify;
        }

        .badge-text {
            background: #fff;
            font-size: 9pt;
            color: #000;
            width: 135px;
            text-align: center;
            padding: 2px;
            font-weight: bold;
            font-family: $montserrat;
            margin-bottom: 30px;

            @media screen and (max-width: 767px) {
                margin-top: -80px;
                line-height: 1.6rem !important;
            }
        }

        h1 {
            line-height: 3.5rem;
            margin-bottom: 25px;
            @media screen and (max-width: 767px) {
                text-align: left;
                line-height: 2rem !important;
            }

            small {
                font-family: Verdana;
                font-weight: bold;
                margin-top: 0;
                color: #ccc;
                font-size: 1.5rem;
                line-height: 1rem !important;
                @media screen and (max-width: 767px) {
                    font-size: 1.1rem;
                    line-height: 1.1rem !important;
                }
            }
        }
    }
    .sticky_header-container {
        display: block;
        text-align: center;
        .btn-form {
            background: dodgerblue !important;
            color: #fff;
        }
    }

    .foot-icon {
        background-image: url(https://www.architettiarezzo.it/wp-content/uploads/2017/12/Immagine-4-560x233.png);
    }
}

.experience {
    font-family: $montserrat;
    font-weight: bold;
    color: #00d8ff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 767px) {
        font-size: 11pt !important;
    }

    @media screen and (max-width: 345px) {
        font-size: 10pt !important;
    }

    strong {
        color: #fff;
    }
}
