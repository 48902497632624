/* Sticky Header */
.v2-sticky_header {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, calc(-100% + -10px), 0);
  transition: 370ms transform;
  width: 100%;
  z-index: 1000;

  &.js_show_sticky_header {
    transform: translate3d(0, 0, 0);
  }
}

/* Sticky Container */
.v2-sticky_header-container {
  max-width: 1280px;
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0.563rem 1rem;

  @media (max-width: 767px) {
    padding: 0.317rem 1rem;
  }

  /* Highlight button */
  .highlight-button {
    color: #fff;
  }
}

.mobile-menu.mobile-menu-open {
  .v2-sticky_header-container {
    margin-top: 10px;
    align-items: flex-start;
  }
}
.desktop-menu {
  .v2-sticky_header-container {
    align-items: center;
  }
}

.v2-sticky_header-container {
  #sticky-menu-icon {
    margin-top: 5px;
  }
}

/* Sticky Header LOGO */
.sticky_header-logo {
  height: 1.5rem;
  @media (max-width: 992px) {
    // mobile
    margin-top: 3px;
  }
}

/* Sticky Header LINKS */
.v2-sticky-header-links {
  @media (min-width: 992px) {
    // desktop
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #000;

    .expand-links {
      &:hover .sub-menu,
      &:active .sub-menu {
        opacity: 1;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        transition: 150ms ease;
      }

      &:hover i.sticky-arrow,
      &:active i.sticky-arrow {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-bottom: 0;
      }
    }
    // DESKTOP MENU
    .sub-menu-container {
      position: absolute;
      top: 10%;
      left: 0;
      width: 160px;
      padding-top: 60px;

      .sub-menu {
        width: 220px;
        opacity: 0;
        display: none;
        overflow: hidden;
        transition: 150ms ease;
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 1);

        .v2-sticky-header-item{
          &:hover,
          &:active {
            border-radius: 0;
            background-color: rgba(0, 0, 0, 0.05) !important;
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    // mobile
    display: none;
  }

  > a,
  > div {
    cursor: pointer;
    margin: 0 10px;
    font-family: $montserrat;
    font-weight: bold;
    transition: 150ms ease;
    font-size: 16px;
    padding: 0.5rem 0.7rem;
    border-radius: 10px;

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .expand-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    i.sticky-arrow {
      margin-bottom: 4px;
    }
  }
}

/* sticky-arrow */
.sticky-arrow {
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Eventpage header */

.event-page .v2-sticky_header {
  background-image: none;

  & #sticky-menu-icon span {
    background: #000;
  }
}

/* Sub-menu */
.v2-sticky_header .sub-menu-container {
  font-size: 14px;

  .sub-menu {
    display: none;
  }

  .v2-sticky-header-item {
    padding: 10px 15px;
    word-wrap: break-word;

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
    }

    span {
      font-weight: 600;
    }
  }
}

/* Mobile menu */
.mobile-menu-open {
  /* Header slice */
  .v2-sticky-header-slice {
    position: absolute;
    background: #fff;
    top: 0;
    height: 1000px;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    left: -10%;
    margin-top: 10px;
    transform: translate(0%, -100%) rotate(-12deg);
    z-index: 1;

    top: -30px;
    height: 240px;
    width: 1000px;
    left: -40px;

    @media (max-width: 360px) {
      top: -50px;
    }
  }

  .v2-sticky-header-links.menu-open > a:hover,
  .v2-sticky-header-links.menu-open > a:active,
  .v2-sticky-header-links.menu-open > div:hover,
  .v2-sticky-header-links.menu-open > div:active {
    background-color: transparent;
  }
  
  .menu-open {
    display: block;
    position: absolute;
    width: 80%;
    top: 60px;
    left: 8%;
    text-align: center;

    @media (max-width: 360px) {
      left: -60px;
      width: 100%;
    }

    > a,
    > div {
      display: inline-block;
      margin: 10px;
      width: 100%;
      border-bottom: 1px solid rgb(231, 231, 231);
    }

    > a:not(.highlight-button),
    > div {
      border-radius: 0px;
    }

    .highlight-button {
      color: #fff;
    }

    .expand-links.open {
      .sub-menu-container {
        padding-top: 30px;

        .sub-menu {
          box-shadow: none;
        }
      }
    }
  }

  .open {
    .toggle-menu-items {
      padding-bottom: 10px;

      i.sticky-arrow {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-bottom: 0;
      }
    }

    .sub-menu {
      opacity: 1;
      display: block;
      transition: 150ms ease;

      .v2-sticky-header-item {
        display: block;
        padding: 10px 0;

        span {
          display: inline-block;
        }

        span:hover,
        span:active {
          &::after {
            width: 100%;
          }
        }
        span::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background: #000;
          transition: width 0.3s;
        }
      }
    }
  }
}

/* Duplicated highlight button */
div.highlight-button-container {
  align-self: flex-start;
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;

  @media (min-width: 993px) {
    display: none;
  }
  @media (max-width: 992px) {
    .highlight-button.button-mobile {
      display: flex;
      padding: 0.5rem 0.7rem;
      font-size: 14px;
      margin-right: 20px;
      font-family: $montserrat;
      font-weight: bold;
      transition: 150ms ease;
    }
    @media (max-width: 354px) {
      display: none;
    }
  }
}

.mobile-menu-open {
  div.highlight-button-container {
    display: none;
  }
}
