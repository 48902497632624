.sticky_header {
  background-color: rgba(255,255,255,1);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, calc(-100% + -5px), 0);
  transition: 370ms transform;
  width: 100%;
  z-index: 1000;

  &.js_show_sticky_header {
    transform: translate3d(0, 0, 0);
  }
}

.sticky_header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1180px;
  padding: 0.563rem 1rem;

  @media (max-width: 767px) {
    padding: 0.317rem 1rem;
  }
}

.sticky_header-logo {
  height: 1.777rem;

  @media (max-width: 767px) {
    height: 1.333rem;
  }
}

.sticky_header-buttons > * + * {
  margin-left: 1rem;
}
