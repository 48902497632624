@keyframes slowpan {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(-800px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.error-page {
  #hero {
    height: 100% !important;
    width: 100%;

    .hero-text {
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
      @media (max-width: 767px) and (max-width: 999px) {
        bottom: 80px;
      }
    }
  }
}

/**
 * HERO
 */
#hero {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  width: 100%;

  @media screen and (max-width: 1000px) and (max-height: 600px) and (orientation: landscape) {
    min-height: 600px;
  }

  @media screen and (max-height: 700px) and (min-width: 1000px) {
    min-height: 700px;
  }

  &::before {
    background-color: rgba(17, 17, 17, 0.15);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .section-background {
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    &.cover-claims {
      bottom: 170px !important;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

      &.visible {
        opacity: 1;
        visibility: visible;
      }
    }

    &:not(.cover-claims) {
      @media screen and (max-width: 991px) {
        background-position: 50% 50%;
      }

      @media screen and (max-width: 767px) and (min-height: 501px) {
        background-position: 60% 50%;
        bottom: 260px;
      }

      @media (max-width: 900px) and (max-height: 500px) {
        background-position: 80% 50%;
      }
    }
  }
}

.slice-element {
  position: absolute;
  left: -20%;
  right: -20%;
  z-index: 0;
  bottom: 0;
  height: 52%;
  transform: rotate(0deg);
  background-color: #000000;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  &.cover-claims {
    z-index: 1 !important;
    height: 52% !important;
  }

  &:not(.cover-claims) {
    @media screen and (max-width: 767px) {
      bottom: -50px;
      height: 250px;
    }

    @media (max-width: 900px) and (max-height: 500px) {
      bottom: -150px;
      height: 200px;
    }

    @media screen and (min-width: 1600px) {
      bottom: -200px;
      height: 400px;
    }
  }
}

#hero {
  overflow: hidden;
}

.hero-container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 20px;
  right: 20px;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;
  z-index: 3;

  &.cover-claims {
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 170px !important;
    top: unset !important;
    transform: translate(0, 0) !important;
  }

  &:not(.cover-claims) {
    @media screen and (max-width: 767px) {
      top: 100%;
      transform: translate(0, -100%);
    }
  }
}

.hero-text {
  position: relative;
  color: $white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  font-size: 4.209rem;
  line-height: 4.3rem;

  &.cover-claims {
    position: absolute;
    text-align: center;
    bottom: 3%;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 9vw;
    line-height: normal;

    @media (max-width: 391px) {
      font-size: 8vw;
    }

    @media (max-width: 900px) and (max-height: 500px) {
      font-size: 8vw;
    }

    @media (min-width: 500px) {
      font-size: 43px;
    }
  }

  &:not(.cover-claims) {
    @media (max-width: 767px) {
      font-size: 2.4rem;
      line-height: 2.5rem;
      transform: translate(0, -180px);
    }

    @media (max-width: 1000px) {
      font-size: 2rem;
      line-height: 2.1rem;
    }

    @media (max-width: 900px) and (max-height: 500px) {
      font-size: 2rem;
      line-height: 2.1rem;
    }

    @media (max-width: 470px) {
      font-size: 2rem;
      line-height: 2.1rem;
    }

    @media (max-width: 420px) {
      font-size: 1.6rem;
      line-height: 1.7rem;
    }
  }
}

.hero-buttons {
  align-items: stretch;
  display: inline-flex;
  margin-top: 1.333rem;
  flex-direction: column;
  z-index: 2;

  &.cover-claims {
    display: flex;
    margin: 0 auto;
    max-width: 438px;
    padding: 16px;
    background: black;
    margin: 0 auto;
    gap: 12px;
  }

  &:not(.cover-claims) {
    @media screen and (max-width: 1024px) and (max-height: 800px) and (orientation: landscape) {
      align-items: stretch;
      flex-direction: row;
    }
  
    @media screen and (max-width: 767px) {
      position: absolute;
      bottom: 20px;
      left: 0px;
      right: 0px;
    }
  }

  .c_button.accent {
    background-color: dodgerblue;
  }
}

.hero-buttons-row {
  display: flex;
  margin-bottom: 0.6rem;
  gap: 16px;

  &.cover-claims {
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 406px) {
      .c_button.ios.show_both, .c_button.android.show_both {
        flex: 0 !important;

        .c_button-icon {
          margin-right: 0 !important;
        }

        .c_button-double_text {
          display: none !important;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) and (max-height: 800px) and (orientation: landscape) {
    margin-bottom: 0;
  }

  & .c_button {
    flex: 1;
    white-space: nowrap;
  }
}

#hero-button {
  @media screen and (max-width: 767px) {
    background-color: transparent;
  }
}

.featured {
  z-index: 2;
  align-items: center;
  bottom: 0;
  color: $white;
  display: flex;
  font-family: $montserrat;
  justify-content: space-between;
  left: 50%;
  max-width: 1180px;
  padding: 1rem;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%);
  width: 100%;

  @media screen and (max-width: 899px) {
    display: none;
  }
}

.featured-title {
  white-space: nowrap;
}

.featured-brand {
  & > img {
    height: 1.777rem;
  }
}

/**
 * FEATURES
 */
#features {
  padding-top: 2.369rem;
  position: relative;
  color: #fff;

  &::before {
    //background: url("../images/card-features-bg.png") no-repeat bottom/cover;
    content: "";
    height: 0px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.card-features {
  margin: auto;
  position: relative;
  width: 100%;
  z-index: 1;
}

.card-features-download {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 560px;
  padding: 0 1rem;
  text-align: center;
}

.card-features-download-buttons {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  display: none;
}

.card-features-download-buttons-row {
  display: flex;
  gap: 16px;
}

.card-features-slider {
  width: 100%;
}

.card-features-slider-slick .slick-track {
  display: flex;
}

.card-features-slider-slick .slick-slide {
  height: inherit !important;
  width: 1024px;

  @media (max-width: 1500px) {
    width: 70vw;
  }

  @media (max-width: 1180px) {
    width: 85vw;
  }

  @media (max-width: 1024px) {
    width: 95vw;
  }

  @media (max-width: 800px) {
    max-width: 375px;
    width: 90vw;
  }
}

.card-features-slider-slick .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0.25rem;
  }

  button {
    background-color: $grey;
    border: none;
    border-radius: 100%;
    display: block;
    height: 0.561rem;
    padding: 0;
    text-indent: -9999px;
    width: 0.561rem;

    &:focus {
      outline: none;
    }
  }

  li.slick-active button {
    background-color: $purple-light;
  }
}

.text-features {
  padding: 5.61rem 3.157rem 3.61rem;

  @media (max-width: 1023px) {
    padding: 5.61rem 1.777rem 3.61rem;
  }
}

.text-features-container {
  display: flex;
  margin: auto;
  max-width: 1080px;
  width: 100%;

  @media (max-width: 900px) {
    align-items: center;
    flex-direction: column;
  }

  & > * {
    @media (max-width: 900px) {
      max-width: 560px;
      width: 100%;
    }
  }

  & > * + * {
    margin-left: 3.6rem;

    @media (max-width: 1023px) {
      margin-left: 1.777rem;
    }

    @media (max-width: 900px) {
      margin-left: 0;
      margin-top: 3.157rem;
    }
  }

  .c-text-feature-description {
    color: #ddd;
  }
  .c-text-feature-title::after {
    background-color: dodgerblue;
    opacity: 0.4;
  }
}

.rich-features {
  margin: auto;
  max-width: 1180px;
  padding: 5.61rem 1.777rem 0;
  width: 100%;

  @media (max-width: 840px) {
    padding: 5.61rem 1rem 0;
  }
}

/**
 * BIKERS
 */
#bikers {
  background: #000;
  color: $white;
  margin-top: 3.157rem;
  padding: 3.157rem 1rem 0;
  text-align: center;

  @media (max-width: 840px) {
    margin-top: 0;
  }
}

.bikers-container {
  margin: auto;
  max-width: 1180px;
  width: 100%;
}

.bikers-title {
  font-size: 2.369rem;
}

.bikers-subtitle {
  margin-top: 0.317rem;
}

.bikers-slider {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 2.369rem 0 4.209rem;
  overflow: hidden;
  max-width: 1180px;
  position: relative;
  width: 100%;

  &::after,
  &::before {
    content: "";
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 5;
  }

  &::before {
    background: linear-gradient(
      90deg,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.738) 19%,
      rgba(0, 0, 0, 0.541) 34%,
      rgba(0, 0, 0, 0.382) 47%,
      rgba(0, 0, 0, 0.278) 56.5%,
      rgba(0, 0, 0, 0.194) 65%,
      rgba(0, 0, 0, 0.126) 73%,
      rgba(0, 0, 0, 0.075) 80.2%,
      rgba(0, 0, 0, 0.042) 86.1%,
      rgba(0, 0, 0, 0.021) 91%,
      rgba(0, 0, 0, 0.008) 95.2%,
      rgba(0, 0, 0, 0.002) 98.2%,
      rgba(0, 0, 0, 0) 100%
    );
    left: 0;
  }

  &::after {
    background: linear-gradient(
      -90deg,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.738) 19%,
      rgba(0, 0, 0, 0.541) 34%,
      rgba(0, 0, 0, 0.382) 47%,
      rgba(0, 0, 0, 0.278) 56.5%,
      rgba(0, 0, 0, 0.194) 65%,
      rgba(0, 0, 0, 0.126) 73%,
      rgba(0, 0, 0, 0.075) 80.2%,
      rgba(0, 0, 0, 0.042) 86.1%,
      rgba(0, 0, 0, 0.021) 91%,
      rgba(0, 0, 0, 0.008) 95.2%,
      rgba(0, 0, 0, 0.002) 98.2%,
      rgba(0, 0, 0, 0) 100%
    );
    right: 0;
  }
}

/**
 * REVIEWS
 */
#reviews {
  background: #000;
  color: $white;
  padding: 0 1rem 3.157rem;
}

.reviews-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1080px;
  width: 100%;
}

.reviews-title {
  font-size: 2.369rem;
  text-align: center;
}

.reviews-stars {
  height: 3.157rem;
  margin-top: 1.333rem;
  width: auto;
}

.reviews-rating {
  color: $grey;
  font-family: $montserrat;
  margin-top: 1rem;
  text-align: center;

  & span:first-of-type {
    color: $yellow;
  }
}

.reviews-list {
  align-items: flex-start;
  color: $grey;
  display: flex;
  font-size: 0.75rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  & > * {
    @media (max-width: 800px) {
      max-width: 560px;
    }
  }

  & > * + * {
    margin-left: 1.777rem;

    @media (max-width: 1080px) {
      margin-left: 1rem;
    }

    @media (max-width: 800px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

/**
 * FAQS
 */
#faqs {
  background: #000;
  color: $white;
  text-align: center;
  padding: 0 1rem 3.157rem;

  .faqs-title {
    font-size: 2.369rem;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 20pt;
    }
  }

  .c-faq-feature-container {
    text-align: left;
  }

  .c_button {
    margin: auto;
  }
}

/**
 * ICON FEATURES
 */
#icon_features.bottom-space {
  padding-bottom: 5.209rem !important;
}

#icon_features,
#first_icon_features {
  position: relative;
  z-index: 10;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2.209rem 1.777rem 0.209rem;

  @media (max-width: 1023px) {
    padding: 2.61rem 1.777rem 0.209rem;
  }
  @media (max-width: 700px) {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }

  .c_icon_feature-title {
    position: relative;
    &::after {
      background-color: dodgerblue;
      opacity: 0.4;
      clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0% 100%);
      content: "";
      height: 1rem;
      left: 20%;
      position: absolute;
      top: 0.763rem;
      width: 60%;
      z-index: -1;
    }
  }
}

.icon_features-container {
  display: flex;
  margin: auto;
  max-width: 1080px;
  padding-bottom: 3.157rem;
  width: 100%;

  @media (max-width: 700px) {
    align-items: center;
    flex-direction: column;
  }

  & > * {
    @media (max-width: 700px) {
      max-width: 560px;
    }
  }

  & > * + * {
    margin-left: 3.6rem;

    @media (max-width: 1023px) {
      margin-left: 1.777rem;
    }

    @media (max-width: 700px) {
      margin-left: 0;
      margin-top: 3.157rem;
    }
  }
  .c_icon_feature-icon {
    filter: invert(100%);
  }
  .c_icon_feature-description {
    color: #ddd;
  }
}

/**
 * SPONSOR
 */
#sponsor {
  background-color: $grey-light;
  padding: 3.157rem 0;
}

#partners {
  background-color: $grey-light;
  padding: 3.157rem 0;
}

.sponsor-title,
.articles-title {
  font-size: 2.369rem;
  color: $black;
  padding: 0 1rem;
  text-align: center;
  max-width: 700px;
  margin: auto;

  @media (max-width: 767px) {
    font-size: 1.8rem;
  }
}

.sponsor-container,
.articles-container {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1080px;
  width: 100%;
}

.sponsor-container .slick-track,
.articles-container .slick-track {
  display: flex;
}

.sponsor-container .slick-slide {
  height: inherit !important;
  margin: 2.369rem 0.75rem;

  @media (max-width: 1024px) {
    max-width: 375px;
    width: 89vw;
  }

  &:first-child {
    margin-left: 1.5rem;
  }

  &:last-child {
    margin-right: 1.5rem;
  }
}

.articles-container .slick-slide {
  height: inherit !important;
  margin: 2.369rem 0.75rem;

  @media (max-width: 1024px) {
    max-width: 375px;
    width: 89vw;
  }

  &:first-child {
    margin-left: 1.5rem;
  }

  &:last-child {
    margin-right: 1.5rem;
  }
}

.sponsor-container .slick-dots,
.articles-container .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0.25rem;
  }

  button {
    background-color: $grey;
    border: none;
    border-radius: 100%;
    display: block;
    height: 0.561rem;
    padding: 0;
    text-indent: -9999px;
    width: 0.561rem;

    &:focus {
      outline: none;
    }
  }

  li.slick-active button {
    background-color: $purple-light;
  }
}

/**
 * DOWNLOAD
 */
section.download {
  height: 650px;
  width: 100%;

  @media screen and (max-width: 600px) {
    height: 660px;
  }

  .download-container {
    background: #000000;
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 0;

    .phone {
      .phone-parts,
      .phone-part {
        position: absolute;
        top: -14%;
        left: 50%;
        margin-left: -280px;
        width: 560px;
        height: 100%;

        .full {
          background-size: contain;
        }
      }

      .phone-part {
        z-index: 10;
        transition: 0.3s transform linear;
      }

      .phone-part[phone-part="3"] {
        z-index: 9;
      }
    }
  }
}

.download-buttons {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  margin: 430px auto 0;
  position: relative;
  z-index: 15;
}

.phone-image {
  overflow: hidden;

  > .full {
    transition: opacity 0.4s linear;
    transition-delay: 0.5s;
    z-index: 2;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.phone-part-element {
  transition: opacity 0.4s linear;
  transition-delay: 0.5s;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.download-buttons-row {
  display: flex;
  gap: 16px;
}

.image-to-preload.alpha {
  > .small {
    transition: opacity 0.1s linear;
  }

  > .full {
    transition: opacity 0.1s linear;
  }
}

.image-to-preload.loaded {
  > .full {
    opacity: 1;
  }

  > .small {
    opacity: 0;
  }
}

.image-to-preload.remove-small {
  > .small {
    display: none;
  }
}
