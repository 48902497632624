.c_rich_feature {
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  position: relative;

  .c_button {
    margin-top: 1rem;
    @media (max-width: 600px) {
      font-size: 12pt;
    }
  }
}

.c_rich_feature-cover_image {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 63%;
  position: absolute;
  width: 100%;

  @media (max-width: 600px) {
    height: 24%;
  }

  &::after {
    background: linear-gradient(
      0,
      rgb(0,0,0) 0%,
      rgba(0,0,0, 0.738) 19%,
      rgba(0,0,0, 0.541) 34%,
      rgba(0,0,0, 0.382) 47%,
      rgba(0,0,0, 0.278) 56.5%,
      rgba(0,0,0, 0.194) 65%,
      rgba(0,0,0, 0.126) 73%,
      rgba(0,0,0, 0.075) 80.2%,
      rgba(0,0,0, 0.042) 86.1%,
      rgba(0,0,0, 0.021) 91%,
      rgba(0,0,0, 0.008) 95.2%,
      rgba(0,0,0, 0.002) 98.2%,
      rgba(0,0,0, 0) 100%
    );
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.c_rich_feature.with-cover-image {
  .c_rich_feature-container {
    padding: 9.969rem 1.333rem 4.209rem;
  }
}

.c_rich_feature-container {
  align-items: flex-start;
  display: flex;
  margin: auto;
  max-width: 978px;
  padding: 4.209rem 1.333rem 4.209rem;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (max-width: 840px) {
    align-items: center;
    flex-direction: column;
    padding: 3.157rem 1.333rem 5.61rem;
  }

  @media (max-width: 600px) {
    padding: 7.478rem 1.333rem 5.61rem;
  }
}

.c_rich_feature-container.no-mockup {
  padding: 3.157rem 0 1.333rem;

  .c_rich_feature-container-phone {
    display: none;
  }

  .c_rich_feature-content {
    max-width: 800px;
    margin: auto;
  }
}

.c_rich_feature-container.squared {
  padding: 1.333rem 0 1.333rem;
  @media (max-width: 767px) {
    text-align: center;
  }
  .c_rich_feature-container-phone {
    display: none;
  }
  
  .c_rich_feature-content {
    position: relative;
    padding-left: 230px;
    margin:auto;
    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  .c_rich_feature-content-image {
    position: absolute;
    left: 0;
    height: 200px;
    width: 200px;
    border-radius: 4px;
    top:50%;
    transform: translate(0, -50%);
    @media (max-width: 767px) {
      transform: translate(-50%, 0);
      position: relative;
      top:0;
      margin-bottom: 20px;
      left: 50%;
    }
  }
}

.c_rich_feature-container.inverted.squared {
  .c_rich_feature-content {
    position: relative;
    padding-right: 230px;
    padding-left: 0px;
    text-align: right;
    @media (max-width: 767px) {
      padding-right: 0;
      text-align: center;
    }
  }
  
  .c_rich_feature-content-image {
    position: absolute;
    left:auto;
    right: 0;
    @media (max-width: 767px) {   
      transform: translate(0, 0);
      position: relative;
      top:0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      right: 0;
    }
  }

}

.c_rich_feature-container.squared.big-image {
  margin-bottom:40px;
  .c_rich_feature-content {
    padding-left: 0;
    padding-right: 0;
  }
  .c_rich_feature-content-image {
    transform: translate(0%, 0);
    position: relative;
    top:0;
    margin-bottom: 20px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    img {
      border-radius: 6px;
      width: 100%;
    }
  }

}

.c_rich_feature-container.has-desc {
  margin-bottom:10px !important;
}

.c_rich_feature-container-phone {
  background-color: #010a1a;
  border-radius: 28px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.03);
  flex-shrink: 0;
  padding: 15px;
  position: relative;
}

.c_rich_feature-text-content {
  padding-bottom: 50px;
}

.c_rich_feature-container-phone-container {
  border-radius: 15px;
  height: 580px;
  overflow: hidden;
  position: relative;
  width: 263px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  @media (max-width: 600px) {
    width: 200px;
    height: 440px;
  }

  & video {
    object-fit: cover;
    display: block;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  & img {
    object-fit: cover;
    display: block;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.c_rich_feature-content {
  color: $white;
  margin: 6.61rem 0 0 4.209rem;
  padding-right: 2.369rem;


  @media (max-width: 1080px) {
    margin: 5.61rem 0 0 2.369rem;
    padding-right: 1.777rem;
  }

  @media (max-width: 840px) {
    margin: 1.777rem 0 0;
    padding-right: 0;
  }
}

.c_rich_feature-content-image {
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_rich_feature-content-tag_title {
  background-color: $white;
  border-radius:4px;
  display: inline-block;
  font-weight: bold;
  font-family: $montserrat;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 2px;
  padding-right:20px;
  padding-left:20px;

  color: $black;

  @media (max-width: 840px) {
    font-size: 11pt;
  }
}

.c_rich_feature-content-title {
  font-size: 2.369rem;
  margin-top: 0.75rem;
  @media (max-width: 840px) {
    font-size: 1.7rem;
  }
}

.c_rich_feature-content-break {
  background-color: dodgerblue;
  height: 0px;
  margin: 0.5rem 0;
  width: 5.61rem;
}
