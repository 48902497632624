$roboto: "Roboto", sans-serif;
$montserrat: 'Montserrat', sans-serif;
$nexa: "Nexa", sans-serif;

h1,
h2,
h3 {
  font-family: $montserrat;
  line-height: 1.25;
  font-weight: bold;
}
