// Set up default styles on the root element:
// set box-sizing to border-box; [1]
// set default font rules; [2]
html {
  box-sizing: border-box; // [1]
  font: 20px/1.5 $roboto; // [2]
  height: 100%;
  overflow-x: hidden;
  width: 100%;

  @media (max-width: 767px) {
    font: 18px/1.5 $roboto;
  }
}

// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #000000;
  height: 100%;
  color: #fff;
}

.main {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;

  &.event-page {
    background-color: #fff;

    & #event-page {
      padding-top: 80px;
      min-height: 100%;
    }
  }
}
