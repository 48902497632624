.c_bike-icon {
  align-items: center;
  border-radius: 12px;
  display: flex;
  height: 2.369rem;
  justify-content: center;
  padding: 8px;
  width: 2.369rem;
  box-shadow: 0 0 6px rgba(0,0,0,0.1);

  &.big {
    border-radius: 28px;
    height: 5.61rem;
    width: 5.61rem;
  }

  & + .c_bike-icon {
    margin-left: 0.563rem;
  }

  &.big + .c_bike-icon {
    margin-left: 1.333rem;
  }

  & > img {
    max-height: 100%;
    max-width: 100%;
  }
}
