$black: #111;
$white: #fff;
$grey: #a3a3a3;
$dark-grey: #363636;
$grey-light: #f4f7fc;
$amaranth: #ed1c5c;
$green: #3cb878;
$green-light: #b0f4d1;
$green-lightest: #e8f9f0;
$yellow: #ffd50b;
$purple: #5e6d99;
$purple-light: #8aa4ef;
