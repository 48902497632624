.c_icon_feature {
	text-align: center;
  flex: 1;
}

.c_icon_feature-icon {
  height: 80px;
  width: auto;
}

.c_icon_feature-title {
  margin-top: 0.563rem;
  font-size: 1rem;
  font-family: $roboto;
  font-size:1.33rem;
  font-weight: 600;
}

.c_icon_feature-description {
  color: $dark-grey;
  font-size: 0.8rem;
  margin-top: 0.317rem;
}
