/* HIGHLIGHT SECTION */
#highlight-section {
  padding: 20px 0 100px 0;
  background-color: #f4f7fc;
  color: #000;

  .highlight-section {
    max-width: 1200px;
    margin: 0 auto;

    h2 {
      text-align: center;
      padding: 30px 0;
      margin-top: 0;
    }

    .c_rich_feature-content-title {
      @media (max-width: 992px) {
        width: 80%;
        max-width: 500px;
        margin-left:auto;
        margin-right: auto;
      }
    }
  }

  .hidden {
    visibility: hidden !important;
  }

  .highlight-section-container {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;


    .c_button {
      box-shadow: 0 2px 5px rgba(0,0,0,0.3);
      border-radius: 50px;
    }


    & > * {
      height: 400px;
      margin: 0 16px;
    }

    #highlight-video {
      order: 0;
      opacity: 0;
    }

    .highlight-info {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      order: 1;
      max-height: 300px;
      height: 200px;

      p {
        margin-bottom: 16px;
      }
    }

    .highlight-video-container {
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      flex-grow: 1;
      width: 60%;
      height: 0;
      padding-top: 38%;

      & iframe {
        object-fit: cover;
      }

      #highlight-video-overlay:hover:after {
        opacity: 0;
      }

      .highlight-image {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      #highlight-video-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: "\A";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          opacity: 1;
          transition: all 0.5s;
          z-index: 1;
        }

        &:hover {
          img.play-btn {
            opacity: 1;
          }
        }

        img.play-btn {
          color: #fff;
          z-index: 2;
          opacity: 0.6;
          transition: opacity 0.5s;
          cursor: pointer;

          &:active {
            transition: all 0.4s ease-out;
            transform: scale(0.7);
          }
        }
      }
    }
  }
}

/* MASCOTTE */
#mascotte {
  z-index: 4;
  position: absolute;
  bottom: 20px;
  right: 0;

  img.mascotte-image {
    width: 400px;
    height: auto;
    animation-name: bounce;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-100px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
}

/* MEDIA QUERY */
#highlight-section {
  @media (max-width: 767px) {
    .highlight-section {
      .highlight-section-container {
        flex-direction: column;

        .highlight-info {
          order: 0;
          text-align: center;
          width: 90%;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 20px;
          padding-top: 30px;
        }

        .highlight-video-container {
          width: 96%;
          order: 1;
          padding-top: 66%;
        }
      }
    }
  }
}

#mascotte {
  @media (max-width: 600px) {
    bottom: 200px;

    img.mascotte-image {
      width: 300px;
    }
  }

  @media (max-width: 389px) {
    bottom: 250px;

    img.mascotte-image {
      width: 200px;
    }
  }
}
