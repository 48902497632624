// Remove default margins
body,
h1,
h2,
h3,
h4,
figure,
ol,
p,
ul {
  margin: 0;
}

// Reset heading font weight
h1,
h2,
h3,
h4 {
  font-weight: normal;
}

// Reset link styles
a {
  color: inherit;
  text-decoration: none;

  @include event {
    text-decoration: none;
  }
}

// Reset list styles
ol,
ul {
  list-style-type: none;
  padding-left: 0;
}
