@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,800;1,400;1,800&display=swap");
body,
h1,
h2,
h3,
h4,
figure,
ol,
p,
ul {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  text-decoration: none;
}

ol,
ul {
  list-style-type: none;
  padding-left: 0;
}

h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
  line-height: 1.25;
  font-weight: bold;
}

html {
  box-sizing: border-box;
  font: 20px/1.5 "Roboto", sans-serif;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 767px) {
  html {
    font: 18px/1.5 "Roboto", sans-serif;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #000000;
  height: 100%;
  color: #fff;
}

.main {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.main.event-page {
  background-color: #fff;
}

.main.event-page #event-page {
  padding-top: 80px;
  min-height: 100%;
}

.c_button {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: #111;
  cursor: pointer;
  display: inline-flex;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  padding: 0.75rem 1rem;
  position: relative;
  transform: translateY(0);
  transition: 150ms ease;
  white-space: nowrap;
  border: none;
  font-size: 1rem;
}

.c_button:hover {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.c_button.dark {
  background-color: #363636;
  color: #fff;
}

.c_button.accent {
  background-color: #5e6d99;
  color: #fff;
}

.c_button.contained {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.c_button.contained svg {
  fill: #fff;
  height: 0.9rem;
  margin-left: 0.563rem;
  width: auto;
}

.c_button.contained.dark {
  background-color: transparent;
  border-color: #111;
  color: #111;
}

.c_button.contained.dark svg {
  fill: #111;
}

.c_button.contained .small {
  padding: calc(0.563rem - 2px) calc(1rem - 3px);
}

.c_button.plus {
  background-color: #ed1c5c;
}

.c_button.plus::before {
  background-color: #ffd50b;
  content: "plus";
  padding: 0 5px;
  position: absolute;
  right: 0;
  top: -0.563rem;
}

@supports (-webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) or (clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) {
  .c_button.plus::before {
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 17% 75%, 0 100%, 0% 75%);
    padding: 0 5px 10px 5px;
  }
}

.c_button.small {
  border-radius: 30px;
  font-size: 0.9rem;
  padding: 0.563rem 1rem;
}

.c_button.small .c_button-icon {
  height: 1.333rem;
  margin-right: 0.317rem;
}

.c_button-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 1.777rem;
  margin-right: 0.563rem;
}

.c_button.ios, .c_button.android {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.c_button.ios .c_button-icon {
  background-image: url("../images/apple-icon.svg");
  width: 30px;
}

.c_button.android .c_button-icon {
  background-image: url("../images/google-play-logo.svg");
  width: 30px;
}

.c_button.ios,
.c_button.android {
  display: none;
}

.c_button-super_text {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 0.563rem;
  font-weight: 600;
  margin-bottom: 0.075rem;
}

.c_card_feature {
  border-radius: 30px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.03);
  margin: 2.369rem 1rem;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1080px) {
  .c_card_feature {
    margin: 2.369rem 0.75rem;
  }
}

.c_card_feature_wrapper {
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}

@media (max-width: 800px) {
  .c_card_feature_wrapper {
    flex-direction: column;
    height: 70vh;
    min-height: 400px;
    max-height: 550px;
  }
}

.c_card_feature-image {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 2.369rem;
  width: calc(50% - 2.369rem);
  z-index: 10;
}

@media (max-width: 800px) {
  .c_card_feature-image {
    height: 50%;
    margin: 0;
    order: 2;
    width: 100%;
    background-position: 50% 100%;
  }
}

.c_card_feature-content {
  display: flex;
  flex-direction: column;
  padding: 1.333rem 9.969% 1rem 2.369rem;
  padding: 1.333rem 9.969% 1rem 0;
  width: 50%;
}

@media (max-width: 1500px) {
  .c_card_feature-content {
    padding: 1.333rem 2.369rem 1rem;
  }
}

@media (max-width: 800px) {
  .c_card_feature-content {
    flex-shrink: 0;
    padding: 1.333rem 1.333rem 1rem;
    width: 100%;
  }
}

.c_card_feature-content .c_button {
  margin-top: auto;
}

.c_card_feature-slice {
  position: absolute;
  left: -20%;
  right: -20%;
  bottom: -100px;
  height: 300px;
  transform: rotate(6deg);
  opacity: 0.5;
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

@media screen and (max-width: 767px) {
  .c_card_feature-slice {
    bottom: -50px;
    height: 250px;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .c_card_feature-slice {
    bottom: -150px;
    height: 200px;
  }
}

.c_card_feature-content-icons {
  display: flex;
}

.c_card_feature-content-text {
  color: #fff;
  font-size: 1.777rem;
  margin: 1rem 0;
}

.c-text-feature {
  flex: 1;
}

.c-text-feature-title {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 1.333rem;
  font-weight: 600;
  position: relative;
}

.c-text-feature-title::after {
  background-color: #b0f4d1;
  clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0% 100%);
  content: "";
  height: 1rem;
  left: 0;
  position: absolute;
  top: 0.563rem;
  width: 170px;
  z-index: -1;
}

.c-text-feature-description {
  color: #363636;
  font-size: 0.8rem;
  margin-top: 0.563rem;
}

.c_rich_feature {
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  position: relative;
}

.c_rich_feature .c_button {
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .c_rich_feature .c_button {
    font-size: 12pt;
  }
}

.c_rich_feature-cover_image {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 63%;
  position: absolute;
  width: 100%;
}

@media (max-width: 600px) {
  .c_rich_feature-cover_image {
    height: 24%;
  }
}

.c_rich_feature-cover_image::after {
  background: linear-gradient(0, black 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.c_rich_feature.with-cover-image .c_rich_feature-container {
  padding: 9.969rem 1.333rem 4.209rem;
}

.c_rich_feature-container {
  align-items: flex-start;
  display: flex;
  margin: auto;
  max-width: 978px;
  padding: 4.209rem 1.333rem 4.209rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

@media (max-width: 840px) {
  .c_rich_feature-container {
    align-items: center;
    flex-direction: column;
    padding: 3.157rem 1.333rem 5.61rem;
  }
}

@media (max-width: 600px) {
  .c_rich_feature-container {
    padding: 7.478rem 1.333rem 5.61rem;
  }
}

.c_rich_feature-container.no-mockup {
  padding: 3.157rem 0 1.333rem;
}

.c_rich_feature-container.no-mockup .c_rich_feature-container-phone {
  display: none;
}

.c_rich_feature-container.no-mockup .c_rich_feature-content {
  max-width: 800px;
  margin: auto;
}

.c_rich_feature-container.squared {
  padding: 1.333rem 0 1.333rem;
}

@media (max-width: 767px) {
  .c_rich_feature-container.squared {
    text-align: center;
  }
}

.c_rich_feature-container.squared .c_rich_feature-container-phone {
  display: none;
}

.c_rich_feature-container.squared .c_rich_feature-content {
  position: relative;
  padding-left: 230px;
  margin: auto;
}

@media (max-width: 767px) {
  .c_rich_feature-container.squared .c_rich_feature-content {
    padding-left: 0;
  }
}

.c_rich_feature-container.squared .c_rich_feature-content-image {
  position: absolute;
  left: 0;
  height: 200px;
  width: 200px;
  border-radius: 4px;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 767px) {
  .c_rich_feature-container.squared .c_rich_feature-content-image {
    transform: translate(-50%, 0);
    position: relative;
    top: 0;
    margin-bottom: 20px;
    left: 50%;
  }
}

.c_rich_feature-container.inverted.squared .c_rich_feature-content {
  position: relative;
  padding-right: 230px;
  padding-left: 0px;
  text-align: right;
}

@media (max-width: 767px) {
  .c_rich_feature-container.inverted.squared .c_rich_feature-content {
    padding-right: 0;
    text-align: center;
  }
}

.c_rich_feature-container.inverted.squared .c_rich_feature-content-image {
  position: absolute;
  left: auto;
  right: 0;
}

@media (max-width: 767px) {
  .c_rich_feature-container.inverted.squared .c_rich_feature-content-image {
    transform: translate(0, 0);
    position: relative;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    right: 0;
  }
}

.c_rich_feature-container.squared.big-image {
  margin-bottom: 40px;
}

.c_rich_feature-container.squared.big-image .c_rich_feature-content {
  padding-left: 0;
  padding-right: 0;
}

.c_rich_feature-container.squared.big-image .c_rich_feature-content-image {
  transform: translate(0%, 0);
  position: relative;
  top: 0;
  margin-bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
}

.c_rich_feature-container.squared.big-image .c_rich_feature-content-image img {
  border-radius: 6px;
  width: 100%;
}

.c_rich_feature-container.has-desc {
  margin-bottom: 10px !important;
}

.c_rich_feature-container-phone {
  background-color: #010a1a;
  border-radius: 28px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.03);
  flex-shrink: 0;
  padding: 15px;
  position: relative;
}

.c_rich_feature-text-content {
  padding-bottom: 50px;
}

.c_rich_feature-container-phone-container {
  border-radius: 15px;
  height: 580px;
  overflow: hidden;
  position: relative;
  width: 263px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

@media (max-width: 600px) {
  .c_rich_feature-container-phone-container {
    width: 200px;
    height: 440px;
  }
}

.c_rich_feature-container-phone-container video {
  object-fit: cover;
  display: block;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.c_rich_feature-container-phone-container img {
  object-fit: cover;
  display: block;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.c_rich_feature-content {
  color: #fff;
  margin: 6.61rem 0 0 4.209rem;
  padding-right: 2.369rem;
}

@media (max-width: 1080px) {
  .c_rich_feature-content {
    margin: 5.61rem 0 0 2.369rem;
    padding-right: 1.777rem;
  }
}

@media (max-width: 840px) {
  .c_rich_feature-content {
    margin: 1.777rem 0 0;
    padding-right: 0;
  }
}

.c_rich_feature-content-image {
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_rich_feature-content-tag_title {
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 2px;
  padding-right: 20px;
  padding-left: 20px;
  color: #111;
}

@media (max-width: 840px) {
  .c_rich_feature-content-tag_title {
    font-size: 11pt;
  }
}

.c_rich_feature-content-title {
  font-size: 2.369rem;
  margin-top: 0.75rem;
}

@media (max-width: 840px) {
  .c_rich_feature-content-title {
    font-size: 1.7rem;
  }
}

.c_rich_feature-content-break {
  background-color: dodgerblue;
  height: 0px;
  margin: 0.5rem 0;
  width: 5.61rem;
}

.c_icon_feature {
  text-align: center;
  flex: 1;
}

.c_icon_feature-icon {
  height: 80px;
  width: auto;
}

.c_icon_feature-title {
  margin-top: 0.563rem;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 1.33rem;
  font-weight: 600;
}

.c_icon_feature-description {
  color: #363636;
  font-size: 0.8rem;
  margin-top: 0.317rem;
}

.c_sponsor_card {
  background-color: #fff;
  color: #111;
  border-radius: 28px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  padding: 0.75rem;
  transform: scale3d(0);
  transition: box-shadow 420ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 300px;
  will-change: box-shadow;
}

@media (hover: hover) {
  .c_sponsor_card:hover {
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.03);
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

.c_sponsor_card.partner {
  box-shadow: none;
  background-color: transparent;
}

.c_sponsor_card.partner .c_sponsor_card-image {
  border: 1px solid #eee;
}

.c_sponsor_card-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 18px;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
}

.c_sponsor_card-content {
  margin-bottom: auto;
  padding: 0 0.563rem;
}

.c_sponsor_card-content-title {
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  font-size: 0.7rem;
  font-weight: 900;
  text-transform: uppercase;
  color: dodgerblue;
}

.c_sponsor_card-content-description {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.333rem;
  line-height: 1.25;
  margin-top: 0.563rem;
}

.c_sponsor_card-action {
  align-self: flex-end;
  height: 0.75rem;
  margin-top: 1rem;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .c_sponsor_card-action {
    opacity: 0;
    visibility: hidden;
  }
}

.c_sponsor_card-action > svg {
  bottom: 7px;
  height: 100%;
  position: relative;
  transform: translate3d(-105%, 0, 0);
  transition: transform 317ms ease;
  width: auto;
}

.c_sponsor_card-action > svg path {
  fill: #8aa4ef;
}

.c_sponsor_card:hover .c_sponsor_card-action > svg {
  transform: translate3d(0, 0, 0);
}

.c_bike-icon {
  align-items: center;
  border-radius: 12px;
  display: flex;
  height: 2.369rem;
  justify-content: center;
  padding: 8px;
  width: 2.369rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.c_bike-icon.big {
  border-radius: 28px;
  height: 5.61rem;
  width: 5.61rem;
}

.c_bike-icon + .c_bike-icon {
  margin-left: 0.563rem;
}

.c_bike-icon.big + .c_bike-icon {
  margin-left: 1.333rem;
}

.c_bike-icon > img {
  max-height: 100%;
  max-width: 100%;
}

.c_review {
  border: 1px solid #363636;
  border-radius: 12px;
  flex: 1;
  margin-top: 1rem;
}

.c_review-blockquote {
  padding: 0.75rem 1rem;
  position: relative;
  padding-left: 3.157rem;
}

.c_review-blockquote::before {
  display: block;
  padding-left: 1rem;
  content: "\201C";
  font-family: sans-serif;
  font-size: 3.157rem;
  position: absolute;
  left: 0;
  top: 0;
}

.c_review-author {
  border-top: 1px solid #363636;
  display: flex;
  padding: 0.75rem 1rem;
}

.c_review-author-avatar {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  flex: none;
  height: 2rem;
  width: 2rem;
}

.c_review-author-info {
  font-size: 0.563rem;
  margin-left: 0.75rem;
}

.c_review-author-info-name {
  font-weight: 700;
  margin-top: 0.25rem;
}

.cookie-banner {
  background-color: rgba(255, 255, 255, 0.95);
  color: #000000;
  font-size: 0.75rem;
  left: 0;
  right: 0;
  width: 80%;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  padding: 1rem 2.369rem;
  position: fixed;
  z-index: 99999;
  display: none;
}

@media (min-width: 768px) {
  .cookie-banner {
    bottom: 20px;
  }
}

@media (max-width: 767px) {
  .cookie-banner {
    padding: 1rem;
    top: 100px;
  }
}

.cookie-banner-container {
  text-align: center;
  max-width: 860px;
  margin: auto;
}

@media (max-width: 800px) {
  .cookie-banner-container {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}

.cookie-banner-container a {
  text-decoration: underline;
}

.cookie-banner-container .close-btn {
  flex-wrap: nowrap;
  margin-left: 1.777rem;
}

@media (max-width: 800px) {
  .cookie-banner-container .close-btn {
    margin-left: 0;
    margin-top: 0.563rem;
  }
}

.cookie-banner-container .c_button {
  border-color: #000;
  color: #000;
  margin-top: 10px;
}

.faqs-container {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 900px;
}

.faqs-container .faqs-title {
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
}

.faqs-container .c-faq-fieature-tag-container {
  position: relative;
  margin-bottom: -15px;
  top: 2px;
  left: 5px;
}

.faqs-container .c-faq-fieature-tag-container .c-faq-fieature-tag {
  display: inline-block;
  font-size: 9pt;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: bold;
  padding: 2px;
  border-radius: 4px;
  color: #fff;
  bottom: -20px;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .faqs-container .c-faq-fieature-tag-container .c-faq-fieature-tag {
    font-size: 8pt;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.faqs-container .c-faq-feature-container {
  border-bottom: 1px solid #444;
  padding-top: 0px;
  position: relative;
  padding-bottom: 0px;
}

.faqs-container .c-faq-feature {
  cursor: pointer;
  position: relative;
  border: none;
  width: 100%;
  text-align: left;
  background-color: transparent;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faqs-container .c-faq-feature-container:hover .c-faq-feature-open {
  transform: translate(0, -50%) rotate(90deg);
  transform-origin: center;
}

.faqs-container .c-faq-feature-title {
  font-family: "Montserrat", sans-serif;
  font-size: 20pt;
  font-weight: 700;
  margin: 0;
  color: #fff;
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  .faqs-container .c-faq-feature-title {
    font-size: 13pt;
  }
}

.faqs-container a {
  color: dodgerblue;
}

.faqs-container p {
  padding-bottom: 10px;
}

.faqs-container .c-faq-feature-description {
  overflow: hidden;
  height: 0;
  margin: 0;
  line-height: 180%;
  font-size: 12pt;
  font-weight: 300;
  font-family: sans-serif;
}

@media screen and (max-width: 767px) {
  .faqs-container .c-faq-feature-description {
    font-size: 10pt;
  }
}

.faqs-container .c-faq-feature-description .c-faq-feature-description-text {
  margin-top: 0px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.faqs-container .c-faq-feature-container.opened .c-faq-feature-description {
  height: auto;
}

.faqs-container .c-faq-feature-container.opened .c-faq-feature-open {
  transform: translate(0, -50%) rotate(45deg) scale(1.5);
}

.faqs-container .c-faq-feature-open, .faqs-container .c-faq-feature-close {
  position: absolute;
  font-family: sans-serif;
  font-size: 18pt;
  font-weight: bold;
  transition: all .3s ease;
  right: 10px;
  top: 50%;
  color: #fff;
  transform: translate(0, -50%);
}

/* hamburger menu icon */
#menu-icon,
#sticky-menu-icon {
  display: none;
}

#menu-icon.open span {
  background-color: #000;
}

@media (max-width: 992px) {
  #menu-icon {
    display: flex;
    width: 32px;
    height: 35px;
    position: relative;
    right: 1rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  #sticky-menu-icon {
    display: flex;
    width: 32px;
    height: 35px;
    right: 1rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  #menu-icon span,
  #sticky-menu-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #menu-icon span {
    background: #fff;
  }
  /* MENU CLOSED */
  #menu-icon span:nth-child(1),
  #sticky-menu-icon span:nth-child(1) {
    top: 6px;
  }
  #menu-icon span:nth-child(2),
  #menu-icon span:nth-child(3),
  #sticky-menu-icon span:nth-child(2),
  #sticky-menu-icon span:nth-child(3) {
    top: 15px;
  }
  #menu-icon span:nth-child(4),
  #sticky-menu-icon span:nth-child(4) {
    top: 24px;
  }
  /* MENU OPENED */
  #menu-icon.open span:nth-child(1),
  #sticky-menu-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  #menu-icon.open span:nth-child(2),
  #sticky-menu-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #menu-icon.open span:nth-child(3),
  #sticky-menu-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #menu-icon.open span:nth-child(4),
  #sticky-menu-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

#articles {
  background-color: #f4f7fc;
  padding: 3.157rem 0;
}

#articles .articles-container + h2 {
  padding-top: 50px;
}

.articles-tag {
  font-size: 2.369rem;
  color: #111;
  padding: 0 1rem;
  text-align: center;
  max-width: 700px;
  margin: auto;
}

.c_article_card {
  background-color: #fff;
  color: #111;
  border-radius: 28px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  padding: 0.75rem;
  transform: scale3d(0);
  transition: box-shadow 420ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 350px;
  will-change: box-shadow;
}

@media (hover: hover) {
  .c_article_card:hover {
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.03);
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

.c_article_card-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 18px;
  height: 250px;
  padding-bottom: 100%;
  width: 100%;
  border: 1px solid #eee;
}

.c_article_card-content {
  margin-bottom: auto;
  padding: 0 0.563rem;
}

.c_article_card-content-tag {
  height: 20px;
  font-family: "Montserrat", sans-serif;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  color: dodgerblue;
  line-height: 1.85;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.c_article_card-content-description {
  height: 70px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.25;
  margin-top: 0.563rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.c_article_card-content-intro {
  height: 70px;
  line-height: 1.25;
  margin-top: 0.563rem;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.c_article_card-action {
  align-self: flex-end;
  height: 0.75rem;
  margin-top: 1rem;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .c_article_card-action {
    opacity: 0;
    visibility: hidden;
  }
}

.c_article_card-action > svg {
  bottom: 7px;
  height: 100%;
  position: relative;
  transform: translate3d(-105%, 0, 0);
  transition: transform 317ms ease;
  width: auto;
}

.c_article_card-action > svg path {
  fill: #8aa4ef;
}

.c_article_card:hover .c_article_card-action > svg {
  transform: translate3d(0, 0, 0);
}

#home-cover-device-container {
  max-width: 1280px;
  width: 100%;
  min-height: 580px;
  position: relative;
}

#home-cover-device-container .device-cover.image,
#home-cover-device-container .device-cover.video {
  width: 245px;
  height: auto;
  border: 12px solid #010a1a;
  border-radius: 30px;
  position: absolute;
  top: 80px;
  right: 0;
  transition: 0.5s ease;
  z-index: 2;
  background-color: #111;
  visibility: visible;
  opacity: 1;
  transition: all .8s ease;
}

#home-cover-device-container .device-cover.image.unvisible,
#home-cover-device-container .device-cover.video.unvisible {
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 767px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    right: 50%;
    border-radius: 20px;
    transform: translate(50%, 0);
    transition: 1s ease;
    width: 190px;
    top: 65px;
  }
}

@media (min-width: 768px) and (max-width: 1340px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    right: 10px;
    width: 240px !important;
  }
}

@media (min-width: 768px) and (max-width: 1340px) and (min-height: 950px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 20px !important;
    width: 300px !important;
  }
}

@media (max-height: 500px) and (max-width: 992px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 80px;
  }
}

@media (min-height: 501px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 80px;
  }
}

@media (min-height: 880px) and (min-width: 1340px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 30px;
    width: 290px;
  }
}

@media (min-height: 880px) and (min-width: 1340px) and (min-height: 900px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 20px;
    width: 300px;
  }
}

@media (min-height: 768px) and (max-height: 860px) and (min-width: 768) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    width: 245px;
    top: 80px;
  }
}

@media (min-height: 768px) and (max-height: 860px) and (min-width: 1289px) and (orientation: portrait) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 25px;
    width: 300px;
  }
}

@media (min-height: 768px) and (max-height: 860px) and (max-width: 400px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: 60px;
    width: 190px;
  }
}

@media (min-height: 1200px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    width: 250px;
  }
}

@media (min-height: 1200px) and (min-width: 912px) {
  #home-cover-device-container .device-cover.image,
  #home-cover-device-container .device-cover.video {
    top: -30px;
    width: 300px !important;
  }
}

/* HIGHLIGHT SECTION */
#highlight-section {
  padding: 20px 0 100px 0;
  background-color: #f4f7fc;
  color: #000;
}

#highlight-section .highlight-section {
  max-width: 1200px;
  margin: 0 auto;
}

#highlight-section .highlight-section h2 {
  text-align: center;
  padding: 30px 0;
  margin-top: 0;
}

@media (max-width: 992px) {
  #highlight-section .highlight-section .c_rich_feature-content-title {
    width: 80%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

#highlight-section .hidden {
  visibility: hidden !important;
}

#highlight-section .highlight-section-container {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
}

#highlight-section .highlight-section-container .c_button {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
}

#highlight-section .highlight-section-container > * {
  height: 400px;
  margin: 0 16px;
}

#highlight-section .highlight-section-container #highlight-video {
  order: 0;
  opacity: 0;
}

#highlight-section .highlight-section-container .highlight-info {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;
  max-height: 300px;
  height: 200px;
}

#highlight-section .highlight-section-container .highlight-info p {
  margin-bottom: 16px;
}

#highlight-section .highlight-section-container .highlight-video-container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  flex-grow: 1;
  width: 60%;
  height: 0;
  padding-top: 38%;
}

#highlight-section .highlight-section-container .highlight-video-container iframe {
  object-fit: cover;
}

#highlight-section .highlight-section-container .highlight-video-container #highlight-video-overlay:hover:after {
  opacity: 0;
}

#highlight-section .highlight-section-container .highlight-video-container .highlight-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

#highlight-section .highlight-section-container .highlight-video-container #highlight-video-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

#highlight-section .highlight-section-container .highlight-video-container #highlight-video-overlay:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: all 0.5s;
  z-index: 1;
}

#highlight-section .highlight-section-container .highlight-video-container #highlight-video-overlay:hover img.play-btn {
  opacity: 1;
}

#highlight-section .highlight-section-container .highlight-video-container #highlight-video-overlay img.play-btn {
  color: #fff;
  z-index: 2;
  opacity: 0.6;
  transition: opacity 0.5s;
  cursor: pointer;
}

#highlight-section .highlight-section-container .highlight-video-container #highlight-video-overlay img.play-btn:active {
  transition: all 0.4s ease-out;
  transform: scale(0.7);
}

/* MASCOTTE */
#mascotte {
  z-index: 4;
  position: absolute;
  bottom: 20px;
  right: 0;
}

#mascotte img.mascotte-image {
  width: 400px;
  height: auto;
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/* MEDIA QUERY */
@media (max-width: 767px) {
  #highlight-section .highlight-section .highlight-section-container {
    flex-direction: column;
  }
  #highlight-section .highlight-section .highlight-section-container .highlight-info {
    order: 0;
    text-align: center;
    width: 90%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  #highlight-section .highlight-section .highlight-section-container .highlight-video-container {
    width: 96%;
    order: 1;
    padding-top: 66%;
  }
}

@media (max-width: 600px) {
  #mascotte {
    bottom: 200px;
  }
  #mascotte img.mascotte-image {
    width: 300px;
  }
}

@media (max-width: 389px) {
  #mascotte {
    bottom: 250px;
  }
  #mascotte img.mascotte-image {
    width: 200px;
  }
}

.header {
  left: 0;
  padding: 1rem 0 1rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-container {
  margin: auto;
  max-width: 1180px;
  width: 100%;
  position: relative;
  z-index: 10;
}

@media (max-width: 767px) {
  .header-container {
    text-align: left;
  }
}

.header-logo {
  display: inline-block;
}

.header-logo > img {
  height: 2.369rem;
}

@media (max-width: 767px) {
  .header-logo > img {
    height: 2.2rem;
  }
}

.header-slice {
  position: absolute;
  background: #fff;
  top: 0;
  height: 1000px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  left: -10%;
  margin-top: 10px;
  transform: translate(0%, -100%) rotate(-12deg);
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .header-slice {
    top: -240px;
    height: 240px;
    width: 1000px;
    margin-top: 0px;
    transform: translate(0%, 0) rotate(-12deg);
    left: -40px;
  }
}

@media screen and (max-width: 767px) {
  .header-slice {
    top: -250px;
    height: 240px;
    width: 1000px;
    left: -40px;
  }
}

/* Header */
.v2-header {
  left: 0;
  position: absolute;
  padding: 1rem 0rem 1rem 1rem;
  top: 0;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.v2-header.desktop-menu {
  padding: 1rem;
}

/* Header logo */
.v2-header-logo {
  display: inline-block;
  max-height: 60px;
}

.v2-header-logo > img {
  height: 2.369rem;
}

@media (max-width: 767px) {
  .v2-header-logo > img {
    height: 2.2rem;
  }
}

@media (max-width: 360px) {
  .v2-header-logo > img {
    height: 1.5rem;
  }
}

/* Header slice */
.v2-header-slice {
  position: absolute;
  background: #fff;
  top: 0;
  height: 1000px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  left: -10%;
  margin-top: 10px;
  transform: translate(0%, -100%) rotate(-12deg);
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .v2-header-slice {
    top: -240px;
    height: 240px;
    width: 1000px;
    margin-top: 0px;
    transform: translate(0%, 0) rotate(-12deg);
    left: -40px;
  }
}

@media screen and (max-width: 767px) {
  .v2-header-slice {
    top: -260px;
    height: 240px;
    width: 1000px;
    left: -40px;
  }
}

@media (max-width: 360px) {
  .v2-header-slice {
    top: -275px;
  }
}

/* Header container */
.v2-header-container {
  margin: auto;
  max-width: 1280px;
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .v2-header-container {
    text-align: left;
  }
}

/* Homepage menu background white */
@media (min-width: 992px) {
  .homepage .v2-header-links > a:hover, .homepage .v2-header-links > a:active,
  .homepage .v2-header-links > div:hover,
  .homepage .v2-header-links > div:active {
    color: #151515;
    background-color: white;
  }
  .homepage .v2-header-item:hover, .homepage .v2-header-item:active {
    background-color: rgba(0, 0, 0, 0.05) !important;
    opacity: 0.8;
  }
}

/* Header Links */
@media (min-width: 992px) {
  .v2-header-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .v2-header-links > a:hover, .v2-header-links > a:active,
  .v2-header-links > div:hover,
  .v2-header-links > div:active {
    background-color: white;
    color: #151515;
  }
  .v2-header-links .expand-links:hover .sub-menu,
  .v2-header-links .expand-links:active .sub-menu {
    opacity: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition: 150ms ease;
  }
  .v2-header-links .expand-links:hover i.arrow,
  .v2-header-links .expand-links:active i.arrow {
    border-color: #000;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: 0;
  }
  .v2-header-links .sub-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 160px;
    padding-top: 54px;
  }
  .v2-header-links .sub-menu-container .sub-menu {
    width: 220px;
    overflow: hidden;
    opacity: 0;
    display: none;
    color: #141414;
    transition: 150ms ease;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  .v2-header-links .sub-menu-container .sub-menu .v2-header-item:hover, .v2-header-links .sub-menu-container .sub-menu .v2-header-item:active {
    background-color: rgba(0, 0, 0, 0.05) !important;
    opacity: 0.8;
  }
}

@media (max-width: 992px) {
  .v2-header-links {
    display: none;
  }
}

.v2-header-links > a,
.v2-header-links > div {
  cursor: pointer;
  margin: 0 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 0.5rem 0.7rem;
  border-radius: 10px;
}

.v2-header-links .expand-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.v2-header-links .expand-links i.arrow {
  margin-bottom: 4px;
}

/* Sub-menu */
.sub-menu-container {
  font-size: 14px;
}

.sub-menu-container .sub-menu {
  display: none;
}

.sub-menu-container .v2-header-item {
  padding: 10px 15px;
  transition: opacity .2s ease;
  word-wrap: break-word;
}

.sub-menu-container .v2-header-item span {
  font-weight: 600;
}

/* Highlight button*/
.highlight-button {
  width: 200px !important;
  padding: 0.6rem 0.8rem;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  width: fit-content !important;
  z-index: 10;
  color: #fff !important;
}

.highlight-button:hover, .highlight-button:active {
  background-color: #43a0fd !important;
}

.v2-header-container #menu-icon {
  margin-top: 5px;
}

/* Arrow */
.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Eventpage header */
.event-page .v2-header {
  background-image: none;
}

.event-page .v2-header #menu-icon span {
  background: #000;
}

.event-page .v2-header .arrow {
  border-color: #000;
}

@media (min-width: 992px) {
  .event-page .v2-header {
    color: #000;
  }
}

/* Mobile menu */
.nav-mobile {
  overflow: hidden;
}

.nav-mobile header {
  overflow: auto;
  overflow-x: hidden;
}

.mobile-menu-open {
  position: fixed;
  background: #fff;
  height: 100vh;
  color: #000;
  z-index: 11;
}

.mobile-menu-open .menu-open {
  display: block;
  position: absolute;
  width: 80%;
  top: 60px;
  left: 8%;
  text-align: center;
}

@media (max-width: 360px) {
  .mobile-menu-open .menu-open {
    left: -60px;
    width: 100%;
  }
}

.mobile-menu-open .menu-open > a,
.mobile-menu-open .menu-open > div {
  display: inline-block;
  margin: 10px;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
}

.mobile-menu-open .menu-open > a:not(.highlight-button),
.mobile-menu-open .menu-open > div {
  border-radius: 0px;
}

.mobile-menu-open .menu-open .highlight-button {
  color: #fff;
}

.mobile-menu-open .menu-open i.arrow {
  border-color: #000;
}

.mobile-menu-open .menu-open .expand-links.open .sub-menu-container {
  padding-top: 30px;
}

.mobile-menu-open .menu-open .expand-links.open .sub-menu-container .sub-menu {
  box-shadow: none;
}

.mobile-menu-open .open .toggle-menu-items {
  padding-bottom: 10px;
}

.mobile-menu-open .open .toggle-menu-items i.arrow {
  border-color: #000;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-bottom: 0;
}

.mobile-menu-open .open .sub-menu {
  opacity: 1;
  display: block;
  transition: 150ms ease;
}

.mobile-menu-open .open .sub-menu .v2-header-item {
  display: block;
  padding: 10px 0;
}

.mobile-menu-open .open .sub-menu .v2-header-item span {
  display: inline-block;
}

.mobile-menu-open .open .sub-menu .v2-header-item span:hover::after,
.mobile-menu-open .open .sub-menu .v2-header-item span:active::after {
  width: 100%;
}

.mobile-menu-open .open .sub-menu .v2-header-item span::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

/* Duplicated highlight button */
@media (max-width: 992px) {
  .v2-header .highlight-button-container .highlight-button.button-mobile {
    margin-right: 40px;
  }
}

@media (max-width: 992px) and (max-width: 576px) {
  .v2-header .highlight-button-container {
    display: none;
  }
}

.sticky_header {
  background-color: white;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, calc(-100% + -5px), 0);
  transition: 370ms transform;
  width: 100%;
  z-index: 1000;
}

.sticky_header.js_show_sticky_header {
  transform: translate3d(0, 0, 0);
}

.sticky_header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1180px;
  padding: 0.563rem 1rem;
}

@media (max-width: 767px) {
  .sticky_header-container {
    padding: 0.317rem 1rem;
  }
}

.sticky_header-logo {
  height: 1.777rem;
}

@media (max-width: 767px) {
  .sticky_header-logo {
    height: 1.333rem;
  }
}

.sticky_header-buttons > * + * {
  margin-left: 1rem;
}

/* Sticky Header */
.v2-sticky_header {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, calc(-100% + -10px), 0);
  transition: 370ms transform;
  width: 100%;
  z-index: 1000;
}

.v2-sticky_header.js_show_sticky_header {
  transform: translate3d(0, 0, 0);
}

/* Sticky Container */
.v2-sticky_header-container {
  max-width: 1280px;
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0.563rem 1rem;
  /* Highlight button */
}

@media (max-width: 767px) {
  .v2-sticky_header-container {
    padding: 0.317rem 1rem;
  }
}

.v2-sticky_header-container .highlight-button {
  color: #fff;
}

.mobile-menu.mobile-menu-open .v2-sticky_header-container {
  margin-top: 10px;
  align-items: flex-start;
}

.desktop-menu .v2-sticky_header-container {
  align-items: center;
}

.v2-sticky_header-container #sticky-menu-icon {
  margin-top: 5px;
}

/* Sticky Header LOGO */
.sticky_header-logo {
  height: 1.5rem;
}

@media (max-width: 992px) {
  .sticky_header-logo {
    margin-top: 3px;
  }
}

/* Sticky Header LINKS */
@media (min-width: 992px) {
  .v2-sticky-header-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #000;
  }
  .v2-sticky-header-links .expand-links:hover .sub-menu,
  .v2-sticky-header-links .expand-links:active .sub-menu {
    opacity: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition: 150ms ease;
  }
  .v2-sticky-header-links .expand-links:hover i.sticky-arrow,
  .v2-sticky-header-links .expand-links:active i.sticky-arrow {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: 0;
  }
  .v2-sticky-header-links .sub-menu-container {
    position: absolute;
    top: 10%;
    left: 0;
    width: 160px;
    padding-top: 60px;
  }
  .v2-sticky-header-links .sub-menu-container .sub-menu {
    width: 220px;
    opacity: 0;
    display: none;
    overflow: hidden;
    transition: 150ms ease;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  .v2-sticky-header-links .sub-menu-container .sub-menu .v2-sticky-header-item:hover, .v2-sticky-header-links .sub-menu-container .sub-menu .v2-sticky-header-item:active {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.05) !important;
    opacity: 0.8;
  }
}

@media (max-width: 992px) {
  .v2-sticky-header-links {
    display: none;
  }
}

.v2-sticky-header-links > a,
.v2-sticky-header-links > div {
  cursor: pointer;
  margin: 0 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  transition: 150ms ease;
  font-size: 16px;
  padding: 0.5rem 0.7rem;
  border-radius: 10px;
}

.v2-sticky-header-links > a:hover, .v2-sticky-header-links > a:active,
.v2-sticky-header-links > div:hover,
.v2-sticky-header-links > div:active {
  background-color: white;
}

.v2-sticky-header-links .expand-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.v2-sticky-header-links .expand-links i.sticky-arrow {
  margin-bottom: 4px;
}

/* sticky-arrow */
.sticky-arrow {
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Eventpage header */
.event-page .v2-sticky_header {
  background-image: none;
}

.event-page .v2-sticky_header #sticky-menu-icon span {
  background: #000;
}

/* Sub-menu */
.v2-sticky_header .sub-menu-container {
  font-size: 14px;
}

.v2-sticky_header .sub-menu-container .sub-menu {
  display: none;
}

.v2-sticky_header .sub-menu-container .v2-sticky-header-item {
  padding: 10px 15px;
  word-wrap: break-word;
}

.v2-sticky_header .sub-menu-container .v2-sticky-header-item:hover, .v2-sticky_header .sub-menu-container .v2-sticky-header-item:active {
  background-color: white;
  border-radius: 10px;
}

.v2-sticky_header .sub-menu-container .v2-sticky-header-item span {
  font-weight: 600;
}

/* Mobile menu */
.mobile-menu-open {
  /* Header slice */
}

.mobile-menu-open .v2-sticky-header-slice {
  position: absolute;
  background: #fff;
  top: 0;
  height: 1000px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  left: -10%;
  margin-top: 10px;
  transform: translate(0%, -100%) rotate(-12deg);
  z-index: 1;
  top: -30px;
  height: 240px;
  width: 1000px;
  left: -40px;
}

@media (max-width: 360px) {
  .mobile-menu-open .v2-sticky-header-slice {
    top: -50px;
  }
}

.mobile-menu-open .v2-sticky-header-links.menu-open > a:hover,
.mobile-menu-open .v2-sticky-header-links.menu-open > a:active,
.mobile-menu-open .v2-sticky-header-links.menu-open > div:hover,
.mobile-menu-open .v2-sticky-header-links.menu-open > div:active {
  background-color: transparent;
}

.mobile-menu-open .menu-open {
  display: block;
  position: absolute;
  width: 80%;
  top: 60px;
  left: 8%;
  text-align: center;
}

@media (max-width: 360px) {
  .mobile-menu-open .menu-open {
    left: -60px;
    width: 100%;
  }
}

.mobile-menu-open .menu-open > a,
.mobile-menu-open .menu-open > div {
  display: inline-block;
  margin: 10px;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
}

.mobile-menu-open .menu-open > a:not(.highlight-button),
.mobile-menu-open .menu-open > div {
  border-radius: 0px;
}

.mobile-menu-open .menu-open .highlight-button {
  color: #fff;
}

.mobile-menu-open .menu-open .expand-links.open .sub-menu-container {
  padding-top: 30px;
}

.mobile-menu-open .menu-open .expand-links.open .sub-menu-container .sub-menu {
  box-shadow: none;
}

.mobile-menu-open .open .toggle-menu-items {
  padding-bottom: 10px;
}

.mobile-menu-open .open .toggle-menu-items i.sticky-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-bottom: 0;
}

.mobile-menu-open .open .sub-menu {
  opacity: 1;
  display: block;
  transition: 150ms ease;
}

.mobile-menu-open .open .sub-menu .v2-sticky-header-item {
  display: block;
  padding: 10px 0;
}

.mobile-menu-open .open .sub-menu .v2-sticky-header-item span {
  display: inline-block;
}

.mobile-menu-open .open .sub-menu .v2-sticky-header-item span:hover::after,
.mobile-menu-open .open .sub-menu .v2-sticky-header-item span:active::after {
  width: 100%;
}

.mobile-menu-open .open .sub-menu .v2-sticky-header-item span::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

/* Duplicated highlight button */
div.highlight-button-container {
  align-self: flex-start;
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
}

@media (min-width: 993px) {
  div.highlight-button-container {
    display: none;
  }
}

@media (max-width: 992px) {
  div.highlight-button-container .highlight-button.button-mobile {
    display: flex;
    padding: 0.5rem 0.7rem;
    font-size: 14px;
    margin-right: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    transition: 150ms ease;
  }
}

@media (max-width: 992px) and (max-width: 354px) {
  div.highlight-button-container {
    display: none;
  }
}

.mobile-menu-open div.highlight-button-container {
  display: none;
}

.footer {
  height: 350px;
  background: #000000;
}

@media screen and (max-width: 767px) {
  .footer {
    height: auto;
    padding-left: 0px;
  }
}

.footer .container {
  position: relative;
  min-height: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .footer .container {
    display: table;
    width: 100%;
    padding-bottom: 150px;
  }
}

.footer .container .foot-icon {
  position: absolute;
  left: 0px;
  margin-top: 20px;
  width: 300px;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../images/logo.png);
}

@media screen and (max-width: 767px) {
  .footer .container .foot-icon {
    width: 100%;
    margin-top: 0;
  }
}

.footer .container .alert {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2, 1.2);
}

@media screen and (max-width: 767px) {
  .footer .container .alert {
    display: none;
  }
}

.footer .container .foot-bottom {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
}

.footer .container .foot-bottom a {
  text-decoration: none !important;
}

.footer .container .foot-bottom .link {
  float: left;
  margin-right: 20px;
  width: auto;
  font-size: 10pt;
  color: #ccc;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-bottom .link {
    float: none;
    margin-right: 0;
  }
}

.footer .container .foot-bottom .link:hover {
  color: #00ffa8;
}

.footer .container .foot-bottom .left {
  position: absolute;
  left: 40px;
  bottom: 20px;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-bottom .left {
    right: 40px;
    text-align: center;
  }
}

.footer .container .foot-bottom .right {
  position: absolute;
  right: 40px;
  bottom: 10px;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-bottom .right {
    bottom: -35px;
    left: 40px;
    text-align: center;
    right: 40px;
  }
}

.footer .container .foot-bottom .copyright {
  color: #ccc;
  font-size: 8pt;
  font-weight: bold;
}

.footer .container .foot-links {
  padding-top: 40px;
  padding-left: 320px;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-links {
    padding-left: 0px;
    padding-top: 140px;
    text-align: center;
  }
}

.footer .container .foot-links .foot-block {
  float: left;
  width: 33%;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-links .foot-block {
    text-align: center;
    width: 100%;
  }
}

.footer .container .foot-links .foot-block .title {
  color: #777;
  font-weight: bold;
  font-size: 8pt;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-links .foot-block .title {
    margin-top: 20px;
    font-size: 10pt;
  }
}

.footer .container .foot-links .foot-block a {
  text-decoration: none !important;
}

.footer .container .foot-links .foot-block .links {
  width: auto;
}

.footer .container .foot-links .foot-block .link {
  font-weight: bold;
  margin-top: 5px;
  color: #ccc;
  font-size: 10pt;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 767px) {
  .footer .container .foot-links .foot-block .link {
    font-size: 12pt;
    display: block;
  }
}

.footer .container .foot-links .foot-block .link:hover {
  color: #00ffa8;
}

.modal {
  background: rgba(0, 0, 0, 0.85);
  color: #111;
  height: 100%;
  min-height: 100%;
  opacity: 0;
  overflow: auto;
  padding: 0 1rem;
  pointer-events: none;
  position: relative;
  transition: opacity 238ms ease;
  visibility: hidden;
  width: 100%;
  z-index: 5000;
}

@media (max-width: 560px) {
  .modal {
    padding: 0 0.75rem;
  }
}

.modal.js-show_modal {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.modal-container {
  background: #fff;
  border-radius: 28px;
  margin: 30px auto;
  max-width: 1180px;
  padding: 5.61rem;
  position: relative;
  width: 100%;
  z-index: 10;
}

@media (max-width: 1040px) {
  .modal-container {
    padding: 4.209rem 2.369rem;
  }
}

@media (max-width: 560px) {
  .modal-container {
    margin: 3.157rem auto;
  }
}

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 2.369rem;
  top: 2.369rem;
}

@media (max-width: 1040px) {
  .modal-close {
    right: 1rem;
    top: 1rem;
  }
}

.modal-close > svg {
  height: 1.777rem;
  transform: rotate(45deg);
  width: 1.777rem;
}

.modal-close > svg > path {
  fill: #111;
  transition: 100ms ease;
}

.modal-close:hover > svg path {
  fill: #a3a3a3;
}

.modal-title {
  font-size: 2.369rem;
}

.modal-features {
  display: flex;
  flex-wrap: wrap;
  margin: -1.579rem -0.889rem;
  padding-top: 0;
}

@media (max-width: 560px) {
  .modal-features {
    margin: -0.5rem 0;
    padding-top: 0;
  }
}

.modal-features-feature {
  flex-basis: 33.333%;
  padding: 1.579rem 0.889rem;
}

@media (max-width: 800px) {
  .modal-features-feature {
    flex-basis: 50%;
  }
}

@media (max-width: 560px) {
  .modal-features-feature {
    flex-basis: 100%;
    padding: 0.5rem 0;
  }
}

.modal-features-feature-icon {
  height: 3.157rem;
  width: auto;
}

.modal-features-feature-title {
  font-size: 1rem;
}

.modal-features-feature-description {
  color: #363636;
  font-size: 0.8rem;
  margin-top: 0.317rem;
}

.floating.in .floating-background {
  opacity: 1;
}

.floating.in .floating-container {
  opacity: 1;
  transform: translate(0, 0);
}

.floating.hidden {
  display: none;
}

.floating {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: auto;
}

.floating .floating-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.floating .floating-container {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  position: relative;
  border-radius: 0;
  z-index: 3;
  width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  left: 0px;
  margin: auto;
  right: 0px;
  top: 0;
  opacity: 0;
  transform: translate(0, 100px);
  transition: all 0.5s ease;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.floating .floating-close {
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  border: none;
  font-weight: bold;
  right: 10px;
  padding: 10px;
  top: 10px;
  font-size: 14pt;
}

.floating .floating-body {
  max-width: 340px;
  margin: auto;
}

.floating .step {
  margin-top: 20px;
  margin-bottom: 20px;
}

.floating .step .step-title {
  margin-bottom: 6px;
  font-weight: bold;
}

.floating .step .step-text {
  margin-bottom: 8px;
  font-size: 10pt;
  line-height: 150%;
  font-family: sans-serif;
}

.floating .step .step-small {
  margin-bottom: 8px;
  font-size: 8pt;
  font-style: italic;
  line-height: 100%;
  font-family: sans-serif;
}

.floating .step .step-event-icon {
  height: 50px;
  margin: auto;
  margin-bottom: 10px;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  height: 60px;
  width: 60px;
}

.floating .qr-code-container {
  margin-top: 20px;
  width: 256px;
  text-align: center;
  position: relative;
}

.floating .qr-code-container img {
  width: 100%;
}

.floating .step-separator {
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background-color: #aaa;
}

.floating .download-cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.floating .download-cta-container .c_button {
  border: 1px solid #ccc;
}

@keyframes slowpan {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-800px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.error-page #hero {
  height: 100% !important;
  width: 100%;
}

.error-page #hero .hero-text {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
}

@media (max-width: 767px) and (max-width: 999px) {
  .error-page #hero .hero-text {
    bottom: 80px;
  }
}

/**
 * HERO
 */
#hero {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

@media screen and (max-width: 1000px) and (max-height: 600px) and (orientation: landscape) {
  #hero {
    min-height: 600px;
  }
}

@media screen and (max-height: 700px) and (min-width: 1000px) {
  #hero {
    min-height: 700px;
  }
}

#hero::before {
  background-color: rgba(17, 17, 17, 0.15);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

#hero .section-background {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

#hero .section-background.cover-claims {
  bottom: 170px !important;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

#hero .section-background.cover-claims.visible {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 991px) {
  #hero .section-background:not(.cover-claims) {
    background-position: 50% 50%;
  }
}

@media screen and (max-width: 767px) and (min-height: 501px) {
  #hero .section-background:not(.cover-claims) {
    background-position: 60% 50%;
    bottom: 260px;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  #hero .section-background:not(.cover-claims) {
    background-position: 80% 50%;
  }
}

.slice-element {
  position: absolute;
  left: -20%;
  right: -20%;
  z-index: 0;
  bottom: 0;
  height: 52%;
  transform: rotate(0deg);
  background-color: #000000;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), black);
}

.slice-element.cover-claims {
  z-index: 1 !important;
  height: 52% !important;
}

@media screen and (max-width: 767px) {
  .slice-element:not(.cover-claims) {
    bottom: -50px;
    height: 250px;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .slice-element:not(.cover-claims) {
    bottom: -150px;
    height: 200px;
  }
}

@media screen and (min-width: 1600px) {
  .slice-element:not(.cover-claims) {
    bottom: -200px;
    height: 400px;
  }
}

#hero {
  overflow: hidden;
}

.hero-container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 20px;
  right: 20px;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;
  z-index: 3;
}

.hero-container.cover-claims {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 170px !important;
  top: unset !important;
  transform: translate(0, 0) !important;
}

@media screen and (max-width: 767px) {
  .hero-container:not(.cover-claims) {
    top: 100%;
    transform: translate(0, -100%);
  }
}

.hero-text {
  position: relative;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  font-size: 4.209rem;
  line-height: 4.3rem;
}

.hero-text.cover-claims {
  position: absolute;
  text-align: center;
  bottom: 3%;
  left: 0;
  right: 0;
  z-index: 1;
  font-size: 9vw;
  line-height: normal;
}

@media (max-width: 391px) {
  .hero-text.cover-claims {
    font-size: 8vw;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .hero-text.cover-claims {
    font-size: 8vw;
  }
}

@media (min-width: 500px) {
  .hero-text.cover-claims {
    font-size: 43px;
  }
}

@media (max-width: 767px) {
  .hero-text:not(.cover-claims) {
    font-size: 2.4rem;
    line-height: 2.5rem;
    transform: translate(0, -180px);
  }
}

@media (max-width: 1000px) {
  .hero-text:not(.cover-claims) {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .hero-text:not(.cover-claims) {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

@media (max-width: 470px) {
  .hero-text:not(.cover-claims) {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

@media (max-width: 420px) {
  .hero-text:not(.cover-claims) {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }
}

.hero-buttons {
  align-items: stretch;
  display: inline-flex;
  margin-top: 1.333rem;
  flex-direction: column;
  z-index: 2;
}

.hero-buttons.cover-claims {
  display: flex;
  margin: 0 auto;
  max-width: 438px;
  padding: 16px;
  background: black;
  margin: 0 auto;
  gap: 12px;
}

@media screen and (max-width: 1024px) and (max-height: 800px) and (orientation: landscape) {
  .hero-buttons:not(.cover-claims) {
    align-items: stretch;
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {
  .hero-buttons:not(.cover-claims) {
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
  }
}

.hero-buttons .c_button.accent {
  background-color: dodgerblue;
}

.hero-buttons-row {
  display: flex;
  margin-bottom: 0.6rem;
  gap: 16px;
}

.hero-buttons-row.cover-claims {
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 406px) {
  .hero-buttons-row.cover-claims .c_button.ios.show_both, .hero-buttons-row.cover-claims .c_button.android.show_both {
    flex: 0 !important;
  }
  .hero-buttons-row.cover-claims .c_button.ios.show_both .c_button-icon, .hero-buttons-row.cover-claims .c_button.android.show_both .c_button-icon {
    margin-right: 0 !important;
  }
  .hero-buttons-row.cover-claims .c_button.ios.show_both .c_button-double_text, .hero-buttons-row.cover-claims .c_button.android.show_both .c_button-double_text {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) and (max-height: 800px) and (orientation: landscape) {
  .hero-buttons-row {
    margin-bottom: 0;
  }
}

.hero-buttons-row .c_button {
  flex: 1;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  #hero-button {
    background-color: transparent;
  }
}

.featured {
  z-index: 2;
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  font-family: "Montserrat", sans-serif;
  justify-content: space-between;
  left: 50%;
  max-width: 1180px;
  padding: 1rem;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%);
  width: 100%;
}

@media screen and (max-width: 899px) {
  .featured {
    display: none;
  }
}

.featured-title {
  white-space: nowrap;
}

.featured-brand > img {
  height: 1.777rem;
}

/**
 * FEATURES
 */
#features {
  padding-top: 2.369rem;
  position: relative;
  color: #fff;
}

#features::before {
  content: "";
  height: 0px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.card-features {
  margin: auto;
  position: relative;
  width: 100%;
  z-index: 1;
}

.card-features-download {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 560px;
  padding: 0 1rem;
  text-align: center;
}

.card-features-download-buttons {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  display: none;
}

.card-features-download-buttons-row {
  display: flex;
  gap: 16px;
}

.card-features-slider {
  width: 100%;
}

.card-features-slider-slick .slick-track {
  display: flex;
}

.card-features-slider-slick .slick-slide {
  height: inherit !important;
  width: 1024px;
}

@media (max-width: 1500px) {
  .card-features-slider-slick .slick-slide {
    width: 70vw;
  }
}

@media (max-width: 1180px) {
  .card-features-slider-slick .slick-slide {
    width: 85vw;
  }
}

@media (max-width: 1024px) {
  .card-features-slider-slick .slick-slide {
    width: 95vw;
  }
}

@media (max-width: 800px) {
  .card-features-slider-slick .slick-slide {
    max-width: 375px;
    width: 90vw;
  }
}

.card-features-slider-slick .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card-features-slider-slick .slick-dots li {
  margin: 0 0.25rem;
}

.card-features-slider-slick .slick-dots button {
  background-color: #a3a3a3;
  border: none;
  border-radius: 100%;
  display: block;
  height: 0.561rem;
  padding: 0;
  text-indent: -9999px;
  width: 0.561rem;
}

.card-features-slider-slick .slick-dots button:focus {
  outline: none;
}

.card-features-slider-slick .slick-dots li.slick-active button {
  background-color: #8aa4ef;
}

.text-features {
  padding: 5.61rem 3.157rem 3.61rem;
}

@media (max-width: 1023px) {
  .text-features {
    padding: 5.61rem 1.777rem 3.61rem;
  }
}

.text-features-container {
  display: flex;
  margin: auto;
  max-width: 1080px;
  width: 100%;
}

@media (max-width: 900px) {
  .text-features-container {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .text-features-container > * {
    max-width: 560px;
    width: 100%;
  }
}

.text-features-container > * + * {
  margin-left: 3.6rem;
}

@media (max-width: 1023px) {
  .text-features-container > * + * {
    margin-left: 1.777rem;
  }
}

@media (max-width: 900px) {
  .text-features-container > * + * {
    margin-left: 0;
    margin-top: 3.157rem;
  }
}

.text-features-container .c-text-feature-description {
  color: #ddd;
}

.text-features-container .c-text-feature-title::after {
  background-color: dodgerblue;
  opacity: 0.4;
}

.rich-features {
  margin: auto;
  max-width: 1180px;
  padding: 5.61rem 1.777rem 0;
  width: 100%;
}

@media (max-width: 840px) {
  .rich-features {
    padding: 5.61rem 1rem 0;
  }
}

/**
 * BIKERS
 */
#bikers {
  background: #000;
  color: #fff;
  margin-top: 3.157rem;
  padding: 3.157rem 1rem 0;
  text-align: center;
}

@media (max-width: 840px) {
  #bikers {
    margin-top: 0;
  }
}

.bikers-container {
  margin: auto;
  max-width: 1180px;
  width: 100%;
}

.bikers-title {
  font-size: 2.369rem;
}

.bikers-subtitle {
  margin-top: 0.317rem;
}

.bikers-slider {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 2.369rem 0 4.209rem;
  overflow: hidden;
  max-width: 1180px;
  position: relative;
  width: 100%;
}

.bikers-slider::after, .bikers-slider::before {
  content: "";
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 5;
}

.bikers-slider::before {
  background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, rgba(0, 0, 0, 0) 100%);
  left: 0;
}

.bikers-slider::after {
  background: linear-gradient(-90deg, black 0%, rgba(0, 0, 0, 0.738) 19%, rgba(0, 0, 0, 0.541) 34%, rgba(0, 0, 0, 0.382) 47%, rgba(0, 0, 0, 0.278) 56.5%, rgba(0, 0, 0, 0.194) 65%, rgba(0, 0, 0, 0.126) 73%, rgba(0, 0, 0, 0.075) 80.2%, rgba(0, 0, 0, 0.042) 86.1%, rgba(0, 0, 0, 0.021) 91%, rgba(0, 0, 0, 0.008) 95.2%, rgba(0, 0, 0, 0.002) 98.2%, rgba(0, 0, 0, 0) 100%);
  right: 0;
}

/**
 * REVIEWS
 */
#reviews {
  background: #000;
  color: #fff;
  padding: 0 1rem 3.157rem;
}

.reviews-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1080px;
  width: 100%;
}

.reviews-title {
  font-size: 2.369rem;
  text-align: center;
}

.reviews-stars {
  height: 3.157rem;
  margin-top: 1.333rem;
  width: auto;
}

.reviews-rating {
  color: #a3a3a3;
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  text-align: center;
}

.reviews-rating span:first-of-type {
  color: #ffd50b;
}

.reviews-list {
  align-items: flex-start;
  color: #a3a3a3;
  display: flex;
  font-size: 0.75rem;
}

@media (max-width: 800px) {
  .reviews-list {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .reviews-list > * {
    max-width: 560px;
  }
}

.reviews-list > * + * {
  margin-left: 1.777rem;
}

@media (max-width: 1080px) {
  .reviews-list > * + * {
    margin-left: 1rem;
  }
}

@media (max-width: 800px) {
  .reviews-list > * + * {
    margin-left: 0;
    margin-top: 1rem;
  }
}

/**
 * FAQS
 */
#faqs {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 0 1rem 3.157rem;
}

#faqs .faqs-title {
  font-size: 2.369rem;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #faqs .faqs-title {
    font-size: 20pt;
  }
}

#faqs .c-faq-feature-container {
  text-align: left;
}

#faqs .c_button {
  margin: auto;
}

/**
 * ICON FEATURES
 */
#icon_features.bottom-space {
  padding-bottom: 5.209rem !important;
}

#icon_features,
#first_icon_features {
  position: relative;
  z-index: 10;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2.209rem 1.777rem 0.209rem;
}

@media (max-width: 1023px) {
  #icon_features,
  #first_icon_features {
    padding: 2.61rem 1.777rem 0.209rem;
  }
}

@media (max-width: 700px) {
  #icon_features,
  #first_icon_features {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
}

#icon_features .c_icon_feature-title,
#first_icon_features .c_icon_feature-title {
  position: relative;
}

#icon_features .c_icon_feature-title::after,
#first_icon_features .c_icon_feature-title::after {
  background-color: dodgerblue;
  opacity: 0.4;
  clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0% 100%);
  content: "";
  height: 1rem;
  left: 20%;
  position: absolute;
  top: 0.763rem;
  width: 60%;
  z-index: -1;
}

.icon_features-container {
  display: flex;
  margin: auto;
  max-width: 1080px;
  padding-bottom: 3.157rem;
  width: 100%;
}

@media (max-width: 700px) {
  .icon_features-container {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .icon_features-container > * {
    max-width: 560px;
  }
}

.icon_features-container > * + * {
  margin-left: 3.6rem;
}

@media (max-width: 1023px) {
  .icon_features-container > * + * {
    margin-left: 1.777rem;
  }
}

@media (max-width: 700px) {
  .icon_features-container > * + * {
    margin-left: 0;
    margin-top: 3.157rem;
  }
}

.icon_features-container .c_icon_feature-icon {
  filter: invert(100%);
}

.icon_features-container .c_icon_feature-description {
  color: #ddd;
}

/**
 * SPONSOR
 */
#sponsor {
  background-color: #f4f7fc;
  padding: 3.157rem 0;
}

#partners {
  background-color: #f4f7fc;
  padding: 3.157rem 0;
}

.sponsor-title,
.articles-title {
  font-size: 2.369rem;
  color: #111;
  padding: 0 1rem;
  text-align: center;
  max-width: 700px;
  margin: auto;
}

@media (max-width: 767px) {
  .sponsor-title,
  .articles-title {
    font-size: 1.8rem;
  }
}

.sponsor-container,
.articles-container {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 1080px;
  width: 100%;
}

.sponsor-container .slick-track,
.articles-container .slick-track {
  display: flex;
}

.sponsor-container .slick-slide {
  height: inherit !important;
  margin: 2.369rem 0.75rem;
}

@media (max-width: 1024px) {
  .sponsor-container .slick-slide {
    max-width: 375px;
    width: 89vw;
  }
}

.sponsor-container .slick-slide:first-child {
  margin-left: 1.5rem;
}

.sponsor-container .slick-slide:last-child {
  margin-right: 1.5rem;
}

.articles-container .slick-slide {
  height: inherit !important;
  margin: 2.369rem 0.75rem;
}

@media (max-width: 1024px) {
  .articles-container .slick-slide {
    max-width: 375px;
    width: 89vw;
  }
}

.articles-container .slick-slide:first-child {
  margin-left: 1.5rem;
}

.articles-container .slick-slide:last-child {
  margin-right: 1.5rem;
}

.sponsor-container .slick-dots,
.articles-container .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sponsor-container .slick-dots li,
.articles-container .slick-dots li {
  margin: 0 0.25rem;
}

.sponsor-container .slick-dots button,
.articles-container .slick-dots button {
  background-color: #a3a3a3;
  border: none;
  border-radius: 100%;
  display: block;
  height: 0.561rem;
  padding: 0;
  text-indent: -9999px;
  width: 0.561rem;
}

.sponsor-container .slick-dots button:focus,
.articles-container .slick-dots button:focus {
  outline: none;
}

.sponsor-container .slick-dots li.slick-active button,
.articles-container .slick-dots li.slick-active button {
  background-color: #8aa4ef;
}

/**
 * DOWNLOAD
 */
section.download {
  height: 650px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  section.download {
    height: 660px;
  }
}

section.download .download-container {
  background: #000000;
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 0;
}

section.download .download-container .phone .phone-parts,
section.download .download-container .phone .phone-part {
  position: absolute;
  top: -14%;
  left: 50%;
  margin-left: -280px;
  width: 560px;
  height: 100%;
}

section.download .download-container .phone .phone-parts .full,
section.download .download-container .phone .phone-part .full {
  background-size: contain;
}

section.download .download-container .phone .phone-part {
  z-index: 10;
  transition: 0.3s transform linear;
}

section.download .download-container .phone .phone-part[phone-part="3"] {
  z-index: 9;
}

.download-buttons {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 430px auto 0;
  position: relative;
  z-index: 15;
}

.phone-image {
  overflow: hidden;
}

.phone-image > .full {
  transition: opacity 0.4s linear;
  transition-delay: 0.5s;
  z-index: 2;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.phone-part-element {
  transition: opacity 0.4s linear;
  transition-delay: 0.5s;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.download-buttons-row {
  display: flex;
  gap: 16px;
}

.image-to-preload.alpha > .small {
  transition: opacity 0.1s linear;
}

.image-to-preload.alpha > .full {
  transition: opacity 0.1s linear;
}

.image-to-preload.loaded > .full {
  opacity: 1;
}

.image-to-preload.loaded > .small {
  opacity: 0;
}

.image-to-preload.remove-small > .small {
  display: none;
}

.page #hero {
  min-height: calc(100% - 200px);
}

@media screen and (max-height: 500px) {
  .page #hero {
    min-height: calc(100% - 50px) !important;
  }
}

@media screen and (max-width: 767px) {
  .page #hero {
    min-height: 52%;
  }
}

.page #hero .section-background {
  background-position: 50% 75%;
}

@media screen and (max-width: 767px) and (min-height: 501px) {
  .page #hero .section-background {
    background-position: 50% 75%;
    bottom: 175px;
  }
}

.page #hero .hero-container {
  max-width: 800px;
  top: 100%;
  transform: translate(0, -100%);
  margin-top: -40px;
}

.page #hero .hero-text {
  font-size: 3.209rem;
  line-height: 3.3rem;
  text-shadow: none;
  transform: translate(0, 0) !important;
}

@media (max-width: 767px) {
  .page #hero .hero-text {
    font-size: 2.4rem;
    line-height: 2.5rem;
  }
}

@media (max-width: 1000px) {
  .page #hero .hero-text {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .page #hero .hero-text {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

@media (max-width: 470px) {
  .page #hero .hero-text {
    font-size: 2rem;
    line-height: 2.1rem;
  }
}

@media (max-width: 420px) {
  .page #hero .hero-text {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }
}

@media (max-width: 330px) {
  .page #hero .hero-text {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }
}

.page #hero .overlay-shadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 70%;
}

.page #hero .slice-element {
  background: #000;
  transform: rotate(-12deg);
  bottom: -160px;
  display: none;
  height: 330px;
}

@media screen and (max-width: 767px) {
  .page #hero .slice-element {
    bottom: -150px;
    height: 250px;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .page #hero .slice-element {
    bottom: -150px;
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .page #hero .accent {
    background-color: dodgerblue;
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  .page #hero .section-background {
    bottom: 0px;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .page #hero .section-background {
    bottom: 0px;
  }
}

.page #form {
  background-color: #f4f7fc;
  padding: 3.157rem 0;
}

.page #form .html-container form {
  color: #000;
}

.page .html-container form {
  color: #fff;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
}

.page .html-container form h2 {
  width: 100%;
  margin-bottom: 10px;
}

.page .html-container form .input-container {
  margin-bottom: 20px;
}

.page .html-container form label {
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.page .html-container form input,
.page .html-container form select,
.page .html-container form textarea {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13pt;
}

.page .html-container form button {
  background-color: dodgerblue;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 40px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.main.privacy.page.clear {
  background-color: white;
}

.main.page section.first-section-to-scroll {
  padding-top: 0 !important;
}

.date-container {
  text-align: right;
  color: #aaa;
  font-size: 12pt;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .date-container {
    text-align: right;
    margin-bottom: 0px;
  }
}

.author-date-wrapper {
  text-align: right;
  width: 100%;
}

.author-date-wrapper.has-top-title {
  margin-top: 20px;
}

.author-container {
  margin-top: 20px;
  position: relative;
  text-align: right;
  margin-right: 20px;
  height: 30px;
}

.author-container .author-avatar {
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translate(0, -50%);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  background-color: #222;
  border: 1px solid #222;
}

.author-container .author-name {
  display: inline-block;
  font-family: "montserrat", sans-serif;
  position: relative;
  padding-right: 60px;
}

.author-container .author-name p {
  font-weight: bold;
}

.body-container {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  line-height: 150%;
  color: #eee;
}

.body-container .intro-title-container {
  padding: 20px;
  padding-top: 0;
}

@media (max-width: 767px) {
  .body-container .intro-title-container {
    padding-top: 20px;
  }
}

.body-container .intro-title {
  font-size: 2.4rem;
  line-height: 2.4rem;
  text-transform: none;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0px;
  text-align: left;
}

@media (max-width: 767px) {
  .body-container .intro-title {
    font-size: 24pt;
    line-height: 28pt;
  }
}

.body-container ol,
.body-container ul {
  list-style-type: disclosure-closed;
  list-style-position: inside;
  padding-left: inherit;
}

.body-container ol li p,
.body-container ul li p {
  display: inline;
}

.body-container blockquote {
  border-left: 2px solid dodgerblue;
  padding-left: 20px;
  margin: 0;
}

.body-container .text-content.intro {
  margin-bottom: 40px;
  margin-top: 0px;
  padding: 0;
}

@media (max-width: 767px) {
  .body-container .text-content.intro {
    padding-left: 20px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
}

.body-container .text-content.intro p {
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  margin: 0;
  border-left: 2px solid dodgerblue;
  padding-left: 20px;
  font-size: 20pt;
  line-height: 140%;
  color: #fff;
}

@media (max-width: 767px) {
  .body-container .text-content.intro p {
    font-size: 14pt;
  }
}

@media (max-width: 800px) {
  .body-container .text-content {
    padding: 20px;
  }
}

.body-container .text-content img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.body-container p {
  font-size: 14pt;
  font-weight: 200;
  margin-bottom: 15px;
}

.body-container p a {
  color: dodgerblue;
}

@media (max-width: 767px) {
  .body-container p {
    font-size: 12pt;
  }
}

@media (max-width: 350px) {
  .body-container p {
    font-size: 11pt;
  }
}

.body-container h1 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  font-size: 4.209rem;
  color: #fff;
  line-height: 4.2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .body-container h1 {
    text-align: center;
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 350px) {
  .body-container h1 {
    font-size: 2.369rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .body-container h1 {
    font-size: 2.369rem;
    line-height: 2.4rem;
  }
}

.body-container h2 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
  color: #fff;
  font-size: 2rem;
  line-height: 2rem;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .body-container h2 {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
}

@media (max-width: 380px) {
  .body-container h2 {
    font-size: 2rem;
    line-height: 2rem;
  }
}

@media (max-width: 900px) and (max-height: 500px) {
  .body-container h2 {
    font-size: 2.3rem;
    line-height: 2.4rem;
  }
}

.body-container h3 {
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.7rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .body-container h3 {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

@media (max-width: 380px) {
  .body-container h3 {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}

.body-container .c_button.accent {
  background: dodgerblue;
}

.body-container .c_button {
  margin-top: 1rem;
  border: none;
  background: #fff;
  font-size: 1rem;
}

.body-container.bottom {
  padding-bottom: 3rem;
}

.body-container.top {
  padding-top: 40px;
}

@media (max-width: 767px) {
  .body-container.top {
    padding-top: 20px;
  }
}

.rich-features {
  padding-top: 2rem;
}

section.table {
  padding-bottom: 7rem;
}

section.table .table-container {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

section.table .table-container .table-row {
  font-size: 1rem;
  color: #ddd;
  font-family: arial, sans-serif;
  position: relative;
  display: block;
  padding: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 600px) {
  section.table .table-container .table-row {
    font-size: 0.8rem;
  }
}

section.table .table-container .table-row .border {
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
  height: 1px;
  background: #fff;
  opacity: 0.1;
}

@media (max-width: 600px) {
  section.table .table-container .table-row .border {
    left: 15px;
    right: 15px;
  }
}

section.table .table-container .table-row .table-column.title {
  width: 50%;
  text-align: left;
}

section.table .table-container .table-row .table-column {
  text-align: center;
  display: inline-block;
  width: 25%;
}

section.table .table-container .table-row .checkbox {
  width: 100%;
  height: 1rem;
  background-image: url("../images/chekmark.png");
  background-size: auto 140%;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 600px) {
  section.table .table-container .table-row .checkbox {
    font-size: 0.8rem;
  }
}

section.table .table-container .table-row.title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 600px) {
  section.table .table-container .table-row.title {
    font-size: 1.2rem;
  }
}

.main.page.maps .first-section-to-scroll, .main.page.maps #hero {
  background: #008F83;
}

.main.page.maps #faqs {
  background: #008F83;
}

.main.page.maps #faqs .c-faq-feature-container {
  border-color: rgba(255, 255, 255, 0.4);
}

.main.page.maps #faqs .c-faq-fieature-tag {
  background: #026e65 !important;
}

.main.page.maps .text-features-container .c-text-feature-title::after {
  background-color: #fff;
  opacity: 0.2;
  z-index: 1;
}

.main.page.maps .body-container .text-content.intro p {
  border-color: #fff;
}

.main.page.maps .c_rich_feature {
  background-color: #026e65;
  border-radius: 40px;
  margin-bottom: 40px;
}

.main.page.maps .c_rich_feature .c_rich_feature-container {
  padding-bottom: 40px;
}

.main.page.maps .c_rich_feature-cover_image::after {
  background: linear-gradient(0, #026e65 0%, rgba(0, 0, 0, 0.1) 80%);
}

.main.page.get-support a {
  top: 16px;
  left: 16px;
  right: 16px;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  width: max-content;
}

.main.page.get-support a img {
  width: auto;
  height: 60px;
}

.main.page.get-support ~ #intercom-container .intercom-messenger-frame.ed8ycz90 {
  bottom: 50% !important;
  right: 50% !important;
  left: 50% !important;
  top: 54% !important;
  transform: translate(-50%, -50%) !important;
  width: 70% !important;
  height: 80% !important;
}

@media screen and (max-width: 450px) {
  .main.page.get-support ~ #intercom-container .intercom-messenger-frame.ed8ycz90 {
    top: 50% !important;
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .page.faqs #hero {
    height: 400px !important;
    min-height: 400px !important;
  }
}

.page.faqs #hero .section-background {
  background-position: 50% 100%;
}

.page.faqs .slice-element {
  display: none;
}

.page.faqs .hero-text {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .page.faqs .hero-container {
    top: 100%;
    transform: translate(0, 0%);
  }
}

.page.route-collection .section-background {
  background: transparent;
}

.page.route-collection .slice-element {
  z-index: 10;
}

.page.route-collection .carousel-background:hover .slick-next {
  transform: translate(0px, 0);
  opacity: 1;
}

.page.route-collection .carousel-background {
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page.route-collection .carousel-background .slick-prev {
  display: none !important;
}

.page.route-collection .form-btn-container {
  text-align: center;
  width: 100%;
}

.page.route-collection .btn-form {
  background: dodgerblue;
  border-radius: 5px;
  padding: 7px;
  padding-left: 25px;
  color: #fff;
  padding-right: 25px;
  transition: all 0.4s ease;
  border: 0px solid #000;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
}

.page.route-collection .btn-form:hover {
  box-shadow: 0 0 10px #fff;
  cursor: pointer;
}

.page.route-collection .slick-next {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  transition: all 0.3s ease;
  position: absolute;
  opacity: 0;
  right: 0;
  transform: translate(20px, 0);
  z-index: 1;
  width: 10%;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  cursor: pointer;
  min-width: 60px;
  top: 0;
  height: 100%;
  border: none;
  outline: none;
  content: "";
  color: transparent;
}

.page.route-collection .slick-next:before {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  content: " ";
  background-size: 40px auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/icons/icon-right-white.png);
}

.page.route-collection .carousel-slide-bg {
  top: 0;
  outline: none;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.page.route-collection #hero {
  min-height: 500px;
  height: 68%;
}

.page.route-collection .slick-list {
  height: 100%;
}

.page.route-collection .slick-track {
  height: 100%;
}

.page.route-collection .slick-track .slick-slide {
  height: 100%;
}

.page.route-collection .mapouter {
  width: 100%;
}

.page.route-collection .gmap_canvas {
  width: 100% !important;
}

.page.route-collection .gmap_canvas iframe {
  width: 100%;
}

.page.route-collection .body-container .text-content {
  text-align: justify !important;
}

.page.route-collection .body-container p {
  text-align: justify;
}

.page.route-collection .body-container .badge-text {
  background: #fff;
  font-size: 9pt;
  color: #000;
  width: 135px;
  text-align: center;
  padding: 2px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .page.route-collection .body-container .badge-text {
    margin-top: -80px;
    line-height: 1.6rem !important;
  }
}

.page.route-collection .body-container h1 {
  line-height: 3.5rem;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .page.route-collection .body-container h1 {
    text-align: left;
    line-height: 2rem !important;
  }
}

.page.route-collection .body-container h1 small {
  font-family: Verdana;
  font-weight: bold;
  margin-top: 0;
  color: #ccc;
  font-size: 1.5rem;
  line-height: 1rem !important;
}

@media screen and (max-width: 767px) {
  .page.route-collection .body-container h1 small {
    font-size: 1.1rem;
    line-height: 1.1rem !important;
  }
}

.page.route-collection .sticky_header-container {
  display: block;
  text-align: center;
}

.page.route-collection .sticky_header-container .btn-form {
  background: dodgerblue !important;
  color: #fff;
}

.page.route-collection .foot-icon {
  background-image: url(https://www.architettiarezzo.it/wp-content/uploads/2017/12/Immagine-4-560x233.png);
}

.experience {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #00d8ff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 767px) {
  .experience {
    font-size: 11pt !important;
  }
}

@media screen and (max-width: 345px) {
  .experience {
    font-size: 10pt !important;
  }
}

.experience strong {
  color: #fff;
}

.page.download #hero {
  height: 200px !important;
  min-height: 200px !important;
}

.page.download .hidden {
  display: none;
}

.page.download .download-buttons {
  text-align: center;
  margin-top: 50px;
}

.page.download .download-buttons img {
  max-width: 240px;
}

.page.download .download-buttons a {
  margin: 0 !important;
}

.page.download .download-buttons .apple img {
  max-width: 210px;
}

.event-page #event-page {
  background-color: #fff;
}

.event-page #event-page .body-container {
  color: #000;
}

.event-page #event-page.top {
  padding-bottom: 50px;
}

.event-page #event-page.bottom {
  padding-top: 50px;
}

.event-page .download {
  height: auto !important;
}

.event-page .download .download-buttons {
  margin: 0 auto 65px !important;
}

.event-card-container {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.event-card-container .show-more-events {
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid #000;
  border-radius: 50px;
  font-weight: bold;
  padding: 10px;
  background-color: #fff;
  color: #000;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;
  font-size: 14pt;
  font-family: "Montserrat", sans-serif;
}

.event-card-container .show-more-events:hover {
  color: #fff;
  background-color: #000;
}

.event-card-container .cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 32px;
}

.event-card-container .cards-container h5 {
  margin: 0 0;
}

.event-card-container .event-card {
  width: 220px;
  height: 350px;
  border-radius: 16px;
  position: relative;
  box-shadow: 1px 5px 15px -5px rgba(149, 149, 149, 0.5);
  background-color: #fff;
  overflow: hidden;
}

.event-card-container .event-card .live-event {
  width: 102px;
  background-color: red;
  padding: 5px 16px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.event-card-container .event-card .live-event .dot {
  display: inline-block;
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.event-card-container .event-card .live-event .pulse {
  border: 3px solid #fff;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 11px;
  top: 5px;
  animation: pulsate 1s ease-out infinite;
  opacity: 0;
}

.event-card-container .event-card .live-event .live-now {
  font-family: "Montserrat", sans-serif;
  margin-left: 4px;
  letter-spacing: 1px;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.event-card-container .event-card .cover-top {
  width: 100%;
  height: 40%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: #555;
}

.event-card-container .event-card .event-image {
  z-index: 1;
  background-color: #444;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 16px;
  box-shadow: 1px 5px 15px -5px rgba(149, 149, 149, 0.5);
}

.event-card-container .event-card .event-image .terminated-tag {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-top: calc(120px - 28px);
  background-color: #8b8b8b;
}

.event-card-container .event-card .event-image .terminated-tag div {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  padding: 2px 0;
}

.event-card-container .event-card .cover-bottom {
  width: 100%;
  height: 60%;
  background-color: #222;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.event-card-container .event-card .cover-bottom .card-title {
  padding: 70px 16px 16px 16px;
}

.event-card-container .event-card .cover-bottom .card-title h3 {
  color: #fff;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.event-card-container .event-card .cover-bottom .card-title .event-date {
  position: absolute;
  top: 265px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.74);
  font-size: 14px;
  font-weight: bold;
  width: 188px;
  text-align: center;
  z-index: 1;
}

.event-card-container .event-card .cover-bottom .event-info {
  background-color: dodgerblue;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 0.3s ease;
  border-radius: 40px;
  padding: 10px;
  color: #fff;
  margin: 12px;
  width: calc(100% - 24px);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.event-card-container .loading .cover-top,
.event-card-container .loading .event-image,
.event-card-container .loading h3,
.event-card-container h1.loading,
.event-card-container .loading .event-date {
  background-color: #ccc;
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #ccc;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.event-card-container h1.loading {
  border-radius: 4px;
  width: 60%;
  height: 50px;
  text-align: center;
}

.event-card-container .loading .cover-bottom {
  background-color: #fff;
}

.event-card-container .loading h3 {
  margin-top: 20px;
  height: 30px;
  border-radius: 4px;
}

.event-card-container .loading .event-date {
  margin-top: 10px;
  width: 80% !important;
  height: 20px;
  border-radius: 4px;
}

.event-card-container .future-events {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
}

.event-card-container .future-events .no-events {
  margin-top: 16px;
}

.event-card-container .future-events h1 {
  margin: 0 16px;
}

.event-card-container .terminated-events {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.event-card-container .terminated-events .no-events {
  margin-top: 16px;
}

.event-card-container .terminated-events h1 {
  margin: 0 16px;
}

.display-none {
  display: none !important;
}
