.c_card_feature {
  border-radius: 30px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  margin: 2.369rem 1rem;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1080px) {
    margin: 2.369rem 0.75rem;
  }
}

.c_card_feature_wrapper {
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 30px;

  @media (max-width: 800px) {
    flex-direction: column;
    height: 70vh;
    min-height: 400px;
    max-height: 550px;
  }
}

.c_card_feature-image {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 2.369rem;
  width: calc(50% - 2.369rem);
  z-index: 10;

  @media (max-width: 800px) {
    height: 50%;
    margin: 0;
    order: 2;
    width: 100%;
    background-position: 50% 100%;
  }
}

.c_card_feature-content {
  display: flex;
  flex-direction: column;
  padding: 1.333rem 9.969% 1rem 2.369rem;
  padding: 1.333rem 9.969% 1rem 0;
  width: 50%;

  @media (max-width: 1500px) {
    padding: 1.333rem 2.369rem 1rem;
  }

  @media (max-width: 800px) {
    flex-shrink: 0;
    padding: 1.333rem 1.333rem 1rem;
    width: 100%;
  }

  & .c_button {
    margin-top: auto;
  }
}

.c_card_feature-slice {
  position: absolute;
  left: -20%;
  right: -20%;
  bottom: -100px;
  height: 300px;
  transform: rotate(6deg);
  opacity: 0.5;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

  @media screen and (max-width: 767px) {
    bottom: -50px;
    height: 250px;
  }

  @media (max-width: 900px) and (max-height: 500px) {
    bottom: -150px;
    height: 200px;
  }
}

.c_card_feature-content-icons {
  display: flex;
}

.c_card_feature-content-text {
  color: $white;
  font-size: 1.777rem;
  margin: 1rem 0;
}
