.event-page {
  #event-page {
    background-color: #fff;

    .body-container {
      color: #000;
    }

    &.top {
      padding-bottom: 50px;
    }

    &.bottom {
      padding-top: 50px;
    }
  }

  .download {
    height: auto !important;

    .download-buttons {
      margin: 0 auto 65px !important;
    }
  }
}

.event-card-container {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  .show-more-events {
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    border: 2px solid #000;
    border-radius: 50px;
    font-weight: bold;
    padding: 10px;
    background-color: #fff;
    color: #000;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
    font-size: 14pt;
    font-family: "Montserrat", sans-serif;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }

  .cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    margin: 32px;

    h5 {
      margin: 0 0;
    }
  }

  .event-card {
    width: 220px;
    height: 350px;
    border-radius: 16px;
    position: relative;
    box-shadow: 1px 5px 15px -5px rgba(149, 149, 149, 0.5);
    background-color: #fff;
    overflow: hidden;

    .live-event {
      width: 102px;
      background-color: red;
      padding: 5px 16px;
      border-radius: 10px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);

      & .dot {
        display: inline-block;
        background-color: #fff;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }

      & .pulse {
        border: 3px solid #fff;
        border-radius: 30px;
        height: 25px;
        width: 25px;
        position: absolute;
        left: 11px;
        top: 5px;
        animation: pulsate 1s ease-out infinite;
        opacity: 0;
      }

      .live-now {
        font-family: "Montserrat", sans-serif;
        margin-left: 4px;
        letter-spacing: 1px;
      }

      @keyframes pulsate {
        0% {
          transform: scale(0.1, 0.1);
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        100% {
          transform: scale(1.2, 1.2);
          opacity: 0;
        }
      }
    }

    .cover-top {
      width: 100%;
      height: 40%;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      background-color: #555;
    }

    .event-image {
      z-index: 1;
      background-color: #444;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
      height: 120px;
      border-radius: 16px;
      box-shadow: 1px 5px 15px -5px rgba(149, 149, 149, 0.5);

      .terminated-tag {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        margin-top: calc(120px - 28px);
        background-color: #8b8b8b;

        div {
          color: #fff;
          font-weight: bold;
          text-align: center;
          font-size: 16px;
          padding: 2px 0;
        }
      }
    }

    .cover-bottom {
      width: 100%;
      height: 60%;
      background-color: #222;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;

      .card-title {
        padding: 70px 16px 16px 16px;

        h3 {
          color: #fff;
          font-size: 16px;
          text-align: center;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
        & .event-date {
          position: absolute;
          top: 265px;
          left: 50%;
          transform: translateX(-50%);
          color: rgba(255, 255, 255, 0.74);
          font-size: 14px;
          font-weight: bold;
          width: 188px;
          text-align: center;
          z-index: 1;
        }
      }

      .event-info {
        background-color: dodgerblue;
        position: absolute;
        bottom: 0;
        right: 0;
        transition: all 0.3s ease;
        border-radius: 40px;
        padding: 10px;
        color: #fff;
        margin: 12px;
        width: calc(100% - 24px);
        font-weight: bold;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .loading .cover-top,
  .loading .event-image,
  .loading h3,
  h1.loading,
  .loading .event-date {
    background-color: #ccc;
    background: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      #ccc;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }

  h1.loading {
    border-radius: 4px;
    width: 60%;
    height: 50px;
    text-align: center;
  }

  .loading {
    .cover-bottom {
      background-color: #fff;
    }

    h3 {
      margin-top: 20px;
      height: 30px;
      border-radius: 4px;
    }

    .event-date {
      margin-top: 10px;
      width: 80% !important;
      height: 20px;
      border-radius: 4px;
    }
  }

  & .future-events {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;

    & .no-events {
      margin-top: 16px;
    }

    h1 {
      margin: 0 16px;
    }
  }

  & .terminated-events {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & .no-events {
      margin-top: 16px;
    }

    h1 {
      margin: 0 16px;
    }
  }
}

.display-none {
  display: none !important;
}
