.page {
  #hero {
    min-height: calc(100% - 200px);
    @media screen and (max-height: 500px) {
      min-height: calc(100% - 50px) !important;
    }
    @media screen and (max-width: 767px) {
      min-height: 52%;
    }
    .section-background {
      background-position: 50% 75%;

      @media screen and (max-width: 767px) and (min-height: 501px) {
        background-position: 50% 75%;
        bottom: 175px;
      }
    }
    .hero-container {
      max-width: 800px;
      top: 100%;
      transform: translate(0, -100%);
      margin-top: -40px;
    }
    .hero-text {
      font-size: 3.209rem;
      line-height: 3.3rem;
      text-shadow: none;
      transform: translate(0, 0) !important;
      @media (max-width: 767px) {
        font-size: 2.4rem;
        line-height: 2.5rem;
      }
      @media (max-width: 1000px) {
        font-size: 2rem;
        line-height: 2.1rem;
      }
      @media (max-width: 900px) and (max-height: 500px) {
        font-size: 2rem;
        line-height: 2.1rem;
      }
      @media (max-width: 470px) {
        font-size: 2rem;
        line-height: 2.1rem;
      }
      @media (max-width: 420px) {
        font-size: 1.6rem;
        line-height: 1.7rem;
      }
      @media (max-width: 330px) {
        font-size: 1.2rem;
        line-height: 1.3rem;
      }
    }

    .overlay-shadow {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
      bottom: 0px;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
      height: 70%;
    }

    .slice-element {
      background: #000;
      transform: rotate(-12deg);
      bottom: -160px;
      display: none;
      height: 330px;
      @media screen and (max-width: 767px) {
        bottom: -150px;
        height: 250px;
      }

      @media (max-width: 900px) and (max-height: 500px) {
        bottom: -150px;
        height: 200px;
      }
    }

    .accent {
      @media screen and (max-width: 767px) {
        background-color: dodgerblue;
        width: 100%;
        margin: auto;
      }
    }

    .section-background {
      @media screen and (max-width: 767px) {
        bottom: 0px;
      }

      @media (max-width: 900px) and (max-height: 500px) {
        bottom: 0px;
      }
    }
  }

  #form {
    background-color: $grey-light;
    padding: 3.157rem 0;
    .html-container {
      form {
        color: #000;
      }
    }
  }

  .html-container {
    form {
      color: #fff;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 800px;
      margin: auto;
      margin-bottom: 40px;
      h2 {
        width: 100%;
        margin-bottom: 10px;
      }
      .input-container {
        margin-bottom: 20px;
      }
      label {
        font-family: $montserrat;
        text-align: left;
      }
      input,
      select,
      textarea {
        font-family: $montserrat;
        width: 100%;
        max-width: 400px;
        border-radius: 5px;
        border: 1px solid #aaa;
        padding: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13pt;
      }

      button {
        background-color: dodgerblue;
        color: #fff;
        font-weight: bold;
        font-family: $montserrat;
        font-size: 14pt;
        text-transform: uppercase;
        padding: 10px;
        border-radius: 40px;
        border: none;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;
      }
    }
  }
}

.main.privacy.page.clear {
  background-color: white;
}

.main.page {
  section.first-section-to-scroll {
    padding-top: 0 !important;
  }
}

.date-container {
  text-align: right;
  color: #aaa;
  font-size: 12pt;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  @media (max-width: 767px) {
    text-align: right;
    margin-bottom: 0px;
  }
}
.author-date-wrapper {
  text-align: right;
  width: 100%;
}
.author-date-wrapper.has-top-title {
  margin-top: 20px;
}

.author-container {
  margin-top: 20px;
  position: relative;
  text-align: right;
  margin-right: 20px;
  height: 30px;

  .author-avatar {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translate(0, -50%);
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    background-color: #222;
    border: 1px solid #222;
  }

  .author-name {
    display: inline-block;
    font-family: "montserrat", sans-serif;
    position: relative;
    padding-right: 60px;
    p {
      font-weight: bold;
    }
  }
}

.body-container {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  line-height: 150%;
  color: #eee;

  .intro-title-container {
    padding: 20px;
    padding-top: 0;
    @media (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .intro-title {
    font-size: 2.4rem;
    line-height: 2.4rem;
    text-transform: none;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0px;
    text-align: left;
    @media (max-width: 767px) {
      font-size: 24pt;
      line-height: 28pt;
    }
  }

  ol,
  ul {
    list-style-type: disclosure-closed;
    list-style-position: inside;
    padding-left: inherit;

    li {
      p {
        display: inline;
      }
    }
  }

  blockquote {
    border-left: 2px solid dodgerblue;
    padding-left: 20px;
    margin: 0;
  }

  .text-content.intro {
    margin-bottom: 40px;
    margin-top: 0px;
    padding: 0;
    @media (max-width: 767px) {
      padding-left: 20px;
      padding-right: 10px;
      margin-bottom: 20px;
    }
    p {
      font-weight: bold;
      font-family: "montserrat", sans-serif;
      margin: 0;
      border-left: 2px solid dodgerblue;
      padding-left: 20px;
      font-size: 20pt;
      line-height: 140%;
      color: #fff;

      @media (max-width: 767px) {
        font-size: 14pt;
      }
    }
  }

  .text-content {
    @media (max-width: 800px) {
      padding: 20px;
    }

    img {
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 14pt;
    font-weight: 200;
    margin-bottom: 15px;

    a {
      color: dodgerblue;
    }

    @media (max-width: 767px) {
      font-size: 12pt;
    }
    @media (max-width: 350px) {
      font-size: 11pt;
    }
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 2rem;
    font-size: 4.209rem;
    color: #fff;
    line-height: 4.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 767px) {
      text-align: center;
      font-size: 2.4rem;
      line-height: 2.4rem;
    }
    @media (max-width: 350px) {
      font-size: 2.369rem;
      line-height: 2.4rem;
    }
    @media (max-width: 900px) and (max-height: 500px) {
      font-size: 2.369rem;
      line-height: 2.4rem;
    }
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
    @media (max-width: 380px) {
      font-size: 2rem;
      line-height: 2rem;
    }
    @media (max-width: 900px) and (max-height: 500px) {
      font-size: 2.3rem;
      line-height: 2.4rem;
    }
  }

  h3 {
    color: #fff;
    font-size: 1.7rem;
    line-height: 1.7rem;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (max-width: 767px) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
    @media (max-width: 380px) {
      font-size: 1.4rem;
      line-height: 1.5rem;
    }
  }

  .c_button.accent {
    background: dodgerblue;
  }
  .c_button {
    margin-top: 1rem;
    border: none;
    background: $white;
    font-size: 1rem;
  }
}

.body-container.bottom {
  padding-bottom: 3rem;
}

.body-container.top {
  padding-top: 40px;
  @media (max-width: 767px) {
    padding-top: 20px;
  }
}

.rich-features {
  padding-top: 2rem;
}

section.table {
  padding-bottom: 7rem;
  .table-container {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    .table-row {
      font-size: 1rem;
      color: #ddd;
      font-family: arial, sans-serif;
      position: relative;
      display: block;
      padding: 12px;
      padding-left: 10px;
      padding-right: 10px;

      @media (max-width: 600px) {
        font-size: 0.8rem;
      }

      .border {
        position: absolute;
        bottom: 0;
        left: 5px;
        right: 5px;
        height: 1px;
        background: #fff;
        opacity: 0.1;

        @media (max-width: 600px) {
          left: 15px;
          right: 15px;
        }
      }

      .table-column.title {
        width: 50%;
        text-align: left;
      }

      .table-column {
        text-align: center;
        display: inline-block;
        width: 25%;
      }

      .checkbox {
        width: 100%;
        height: 1rem;
        background-image: url("../images/chekmark.png");
        background-size: auto 140%;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 600px) {
          font-size: 0.8rem;
        }
      }
    }

    .table-row.title {
      color: $white;
      font-family: $montserrat;
      font-size: 1.8rem;
      padding-top: 10px;
      padding-bottom: 10px;

      @media (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
  }
}

.main.page.maps {
  .first-section-to-scroll, #hero {
    background: #008F83;
    //#026e65
  }

  #faqs{
    background:#008F83;

    .c-faq-feature-container {
      border-color: rgba(255,255,255,0.4);
    }

    .c-faq-fieature-tag {
      background:#026e65 !important;
    }
  }

  .text-features-container .c-text-feature-title::after{
    background-color: #fff;
    opacity: 0.2;
    z-index: 1;
  }

  .body-container .text-content.intro p {
    border-color: #fff;
  }

  .c_rich_feature {
    background-color: #026e65;
    border-radius: 40px;
    margin-bottom: 40px;

    .c_rich_feature-container   {
      padding-bottom: 40px;
    }
  }

  .c_rich_feature-cover_image::after {
    background: linear-gradient(0,#026e65 0%, rgba(0, 0, 0, 0.1) 80%)
  }
}

.main.page.get-support a {
  top: 16px;
  left: 16px;
  right: 16px;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  width: max-content;

  img {
    width: auto;
    height: 60px;
  }
}

.main.page.get-support ~ #intercom-container {
  .intercom-messenger-frame.ed8ycz90 {
    bottom: 50% !important;
    right: 50% !important;
    left: 50% !important;
    top: 54% !important;
    transform: translate(-50%, -50%) !important;
    width: 70% !important;
    height: 80% !important;

    @media screen and (max-width: 450px) {
      top: 50% !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}