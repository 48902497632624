/* hamburger menu icon */
#menu-icon,
#sticky-menu-icon {
  display: none;
}

#menu-icon {
  &.open span {
    background-color: #000;
  }
}

@media (max-width: 992px) {
  #menu-icon {
    display: flex;
    width: 32px;
    height: 35px;
    position: relative;
    right: 1rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #sticky-menu-icon {
    display: flex;
    width: 32px;
    height: 35px;
    right: 1rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #menu-icon span,
  #sticky-menu-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #menu-icon span {
    background: #fff;
  }

  /* MENU CLOSED */
  #menu-icon span:nth-child(1),
  #sticky-menu-icon span:nth-child(1) {
    top: 6px;
  }

  #menu-icon span:nth-child(2),
  #menu-icon span:nth-child(3),
  #sticky-menu-icon span:nth-child(2),
  #sticky-menu-icon span:nth-child(3) {
    top: 15px;
  }

  #menu-icon span:nth-child(4),
  #sticky-menu-icon span:nth-child(4) {
    top: 24px;
  }

  /* MENU OPENED */
  #menu-icon.open span:nth-child(1),
  #sticky-menu-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #menu-icon.open span:nth-child(2),
  #sticky-menu-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #menu-icon.open span:nth-child(3),
  #sticky-menu-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #menu-icon.open span:nth-child(4),
  #sticky-menu-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}
