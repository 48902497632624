#articles {
  background-color: #f4f7fc;
  padding: 3.157rem 0;

  .articles-container + h2 {
    padding-top: 50px;
  }
}

.articles-tag {
  font-size: 2.369rem;
  color: #111;
  padding: 0 1rem;
  text-align: center;
  max-width: 700px;
  margin: auto;
}

.c_article_card {
  background-color: $white;
  color: $black;
  border-radius: 28px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  padding: 0.75rem;
  transform: scale3d(0);
  transition: box-shadow 420ms cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 350px;
  will-change: box-shadow;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
        0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.03);
      transform: scale3d(1.05, 1.05, 1.05);
    }
  }
}

.c_article_card-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 18px;
  height: 250px;
  padding-bottom: 100%;
  width: 100%;
  border: 1px solid #eee;
}

.c_article_card-content {
  margin-bottom: auto;
  padding: 0 0.563rem;
}

.c_article_card-content-tag {
  height: 20px;
  font-family: $montserrat;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  color: dodgerblue;
  line-height: 1.85;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.c_article_card-content-description {
  height: 70px;
  font-family: $roboto;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.25;
  margin-top: 0.563rem;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.c_article_card-content-intro {
  height: 70px;
  line-height: 1.25;
  margin-top: 0.563rem;
  font-size: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.c_article_card-action {
  align-self: flex-end;
  height: 0.75rem;
  margin-top: 1rem;
  overflow: hidden;

  @media (max-width: 1024px) {
    opacity: 0;
    visibility: hidden;
  }

  & > svg {
    bottom: 7px;
    height: 100%;
    position: relative;
    transform: translate3d(-105%, 0, 0);
    transition: transform 317ms ease;
    width: auto;

    & path {
      fill: $purple-light;
    }
  }
}

.c_article_card:hover {
  & .c_article_card-action > svg {
    transform: translate3d(0, 0, 0);
  }
}
