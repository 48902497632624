.page.faqs {
    #hero {
        @media screen and (max-width: 767px) {
            height: 400px !important;
            min-height: 400px !important;
        }

        .section-background {
			background-position:50% 100%;
        }
    }
    .slice-element {
        display: none;
    }

    .hero-text {
        text-align: center;
    }

    .hero-container {
        @media screen and (max-width: 767px) {
            top:100%;
            transform: translate(0, 0%);
        }
    }
}