.c_review {
  border: 1px solid $dark-grey;
  border-radius: 12px;
  flex: 1;
  margin-top: 1rem;
}

.c_review-blockquote {
  padding: 0.75rem 1rem;
  position: relative;
  padding-left: 3.157rem;

  &::before {
    display: block;
    padding-left: 1rem;
    content: "\201C";
    font-family: sans-serif;
    font-size: 3.157rem;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.c_review-author {
  border-top: 1px solid $dark-grey;
  display: flex;
  padding: 0.75rem 1rem;
}

.c_review-author-avatar {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  flex: none;
  height: 2rem;
  width: 2rem;
}

.c_review-author-info {
  font-size: 0.563rem;
  margin-left: 0.75rem;
}

.c_review-author-info-name {
  font-weight: 700;
  margin-top: 0.25rem;
}
