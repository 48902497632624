/* Header */
.v2-header {
  left: 0;
  position: absolute;
  padding: 1rem 0rem 1rem 1rem;
  top: 0;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4)
  );
  &.desktop-menu {
    padding: 1rem;
  }
}

/* Header logo */
.v2-header-logo {
  display: inline-block;
  max-height: 60px;

  & > img {
    height: 2.369rem;

    @media (max-width: 767px) {
      height: 2.2rem;
    }

    @media (max-width: 360px) {
      height: 1.5rem;
    }
  }
}

/* Header slice */
.v2-header-slice {
  position: absolute;
  background: #fff;
  top: 0;
  height: 1000px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  left: -10%;
  margin-top: 10px;
  transform: translate(0%, -100%) rotate(-12deg);
  z-index: 1;

  @media screen and (max-width: 1200px) {
    top: -240px;
    height: 240px;
    width: 1000px;
    margin-top: 0px;
    transform: translate(0%, 0) rotate(-12deg);
    left: -40px;
  }

  @media screen and (max-width: 767px) {
    top: -260px;
    height: 240px;
    width: 1000px;
    left: -40px;
  }

  @media (max-width: 360px) {
    top: -275px;
  }
}

/* Header container */
.v2-header-container {
  margin: auto;
  max-width: 1280px;
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    text-align: left;
  }
}

/* Homepage menu background white */
@media (min-width: 992px) {
  .homepage {
    .v2-header-links {
      > a,
      > div {
        &:hover,
        &:active {
          color:#151515;
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
    .v2-header-item {
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.05) !important;
        opacity: 0.8;
      }
    }
  }
}

/* Header Links */
.v2-header-links {
  @media (min-width: 992px) {
    // desktop
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > a,
    > div {
      &:hover,
      &:active {
        background-color: rgba(255, 255, 255, 1);
        color:#151515;
      }
    }

    .expand-links {
      &:hover .sub-menu,
      &:active .sub-menu {
        opacity: 1;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        transition: 150ms ease;
      }

      &:hover i.arrow,
      &:active i.arrow {
        border-color: #000;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-bottom: 0;
      }
    }
    // DESKTOP MENU
    .sub-menu-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 160px;
      padding-top: 54px;

      .sub-menu {
        width: 220px;
        overflow: hidden;
        opacity: 0;
        display: none;
        color:#141414;
        transition: 150ms ease;
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 1);

        .v2-header-item {
          &:hover,
          &:active {
            background-color: rgba(0, 0, 0, 0.05) !important;
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    // mobile
    display: none;
  }

  > a,
  > div {
    cursor: pointer;
    margin: 0 10px;
    font-family: $montserrat;
    font-weight: bold;
    font-size: 16px;
    padding: 0.5rem 0.7rem;
    border-radius: 10px;
  }

  .expand-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    i.arrow {
      margin-bottom: 4px;
    }
  }
}

/* Sub-menu */
.sub-menu-container {
  font-size: 14px;

  .sub-menu {
    display: none;
  }

  .v2-header-item {
    padding: 10px 15px;
    transition: opacity .2s ease;
    word-wrap: break-word;
    span {
      font-weight: 600;
    }
  }
}

/* Highlight button*/
.highlight-button {
  width: 200px !important;
  padding: 0.6rem 0.8rem;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  width: fit-content !important;
  z-index: 10;
  color:#fff !important;

  &:hover,
  &:active {
    background-color: #43a0fd !important;
  }
}

.v2-header-container {
  #menu-icon {
    margin-top: 5px;
  }
}

/* Arrow */
.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Eventpage header */

.event-page .v2-header {
  background-image: none;

  & #menu-icon span {
    background: #000;
  }
  .arrow {
    border-color:#000;
  }
}

@media (min-width: 992px) {
  .event-page .v2-header {
    color: #000;
  }
}

/* Mobile menu */
.nav-mobile {
  overflow: hidden;

  header {
    overflow: auto;
    overflow-x: hidden;
  }
}

.mobile-menu-open {
  position: fixed;
  background: #fff;
  height: 100vh;
  color: #000;
  z-index: 11;

  .menu-open {
    display: block;
    position: absolute;
    width: 80%;
    top: 60px;
    left: 8%;
    text-align: center;

    @media (max-width: 360px) {
      left: -60px;
      width: 100%;
    }

    > a,
    > div {
      display: inline-block;
      margin: 10px;
      width: 100%;
      border-bottom: 1px solid rgb(231, 231, 231);
    }

    > a:not(.highlight-button),
    > div {
      border-radius: 0px;
    }

    .highlight-button {
      color: #fff;
    }

    i.arrow {
      border-color: #000;
    }

    .expand-links.open {
      .sub-menu-container {
        padding-top: 30px;

        .sub-menu {
          box-shadow: none;
        }
      }
    }
  }

  .open {
    .toggle-menu-items {
      padding-bottom: 10px;

      i.arrow {
        border-color: #000;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin-bottom: 0;
      }
    }

    .sub-menu {
      opacity: 1;
      display: block;
      transition: 150ms ease;

      .v2-header-item {
        display: block;
        padding: 10px 0;

        span {
          display: inline-block;
        }

        span:hover,
        span:active {
          &::after {
            width: 100%;
          }
        }
        span::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background: #000;
          transition: width 0.3s;
        }
      }
    }
  }
}

/* Duplicated highlight button */
.v2-header {
  .highlight-button-container {
    @media (max-width: 992px) {
      .highlight-button.button-mobile {
        margin-right: 40px;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
  }
}
