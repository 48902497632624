.c_button {
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: $black;
  cursor: pointer;
  display: inline-flex;
  font-family: $montserrat;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  padding: 0.75rem 1rem;
  position: relative;
  transform: translateY(0);
  transition: 150ms ease;
  white-space: nowrap;
  border:none;
  font-size: 1rem;

  &:hover {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
  }

  &.dark {
    background-color: $dark-grey;
    color: $white;
  }

  &.accent {
    background-color: $purple;
    color: $white;
  }

  &.contained {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;

    & svg {
      fill: $white;
      height: 0.9rem;
      margin-left: 0.563rem;
      width: auto;
    }

    &.dark {
      background-color: transparent;
      border-color: $black;
      color: $black;

      & svg {
        fill: $black;
      }
    }

    .small {
      padding: calc(0.563rem - 2px) calc(1rem - 3px);
    }
  }

  &.plus {
    background-color: $amaranth;

    &::before {
      background-color: $yellow;
      content: "plus";
      padding: 0 5px;
      position: absolute;
      right: 0;
      top: -0.563rem;
      @supports (-webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) or
        (clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) {
        clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 17% 75%, 0 100%, 0% 75%);
        padding: 0 5px 10px 5px;
      }
    }
  }

  &.small {
    border-radius: 30px;
    font-size: 0.9rem;
    padding: 0.563rem 1rem;

    & .c_button-icon {
      height: 1.333rem;
      margin-right: 0.317rem;
    }
  }
}

.c_button-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 1.777rem;
  margin-right: 0.563rem;
}

.c_button.ios, .c_button.android {
  padding-top: 0.6rem;
  padding-bottom:0.6rem;
}

.c_button {
  &.ios .c_button-icon {
    background-image: url("../images/apple-icon.svg");
    width: 30px;
  }
  &.android .c_button-icon {
    background-image: url("../images/google-play-logo.svg");
    width: 30px;
  }
}

// Needed for user-agent
.c_button.ios,
.c_button.android {
  display: none;
}

.c_button-super_text {
  display: block;
  font-family: $roboto;
  font-size: 0.563rem;
  font-weight: 600;
  margin-bottom: 0.075rem;
}
