.footer {
  height: 350px;
  background: #000000;

  @media screen and (max-width: 767px) {
    height: auto;
    padding-left: 0px;
  }

  .container {
    position: relative;
    min-height: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 767px) {
      display: table;
      width: 100%;
      padding-bottom: 150px;
    }

    .foot-icon {
      position: absolute;
      left: 0px;
      margin-top: 20px;
      width: 300px;
      height: 150px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../images/logo.png);

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 0;
      }
    }

    .alert {
      position: absolute;
      bottom: 100px;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.2, 1.2);

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .foot-bottom {
      position: absolute;
      bottom: 40px;
      left: 0;
      width: 100%;

      a {
        text-decoration: none !important;
      }

      .link {
        float: left;
        margin-right: 20px;
        width: auto;
        font-size: 10pt;
        color: #ccc;
        text-decoration: none;

        @media screen and (max-width: 767px) {
          float: none;
          margin-right: 0;
        }
      }

      .link:hover {
        color: #00ffa8;
      }

      .left {
        position: absolute;
        left: 40px;
        bottom: 20px;

        @media screen and (max-width: 767px) {
          right: 40px;
          text-align: center;
        }
      }

      .right {
        position: absolute;
        right: 40px;
        bottom: 10px;

        @media screen and (max-width: 767px) {
          bottom: -35px;
          left: 40px;
          text-align: center;
          right: 40px;
        }
      }

      .copyright {
        color: #ccc;
        font-size: 8pt;
        font-weight: bold;
      }
    }

    .foot-links {
      padding-top: 40px;
      padding-left: 320px;

      @media screen and (max-width: 767px) {
        padding-left: 0px;
        padding-top: 140px;
        text-align: center;
      }

      .foot-block {
        float: left;
        width: 33%;

        @media screen and (max-width: 767px) {
          text-align: center;
          width: 100%;
        }

        .title {
          color: #777;
          font-weight: bold;
          font-size: 8pt;
          font-family: "Montserrat", sans-serif;

          @media screen and (max-width: 767px) {
            margin-top: 20px;
            font-size: 10pt;
          }
        }

        a {
          text-decoration: none !important;
        }

        .links {
          width: auto;
        }

        .link {
          font-weight: bold;
          margin-top: 5px;
          color: #ccc;
          font-size: 10pt;
          font-family: "Montserrat", sans-serif;

          @media screen and (max-width: 767px) {
            font-size: 12pt;
            display: block;
          }
        }

        .link:hover {
          color: #00ffa8;
        }
      }
    }
  }
}
