#home-cover-device-container {
  max-width: 1280px;
  width: 100%;
  min-height: 580px;
  position: relative;

  .device-cover.image,
  .device-cover.video {
    width: 245px;
    height: auto;
    border: 12px solid #010a1a;
    border-radius: 30px;
    position: absolute;
    top: 80px;
    right: 0;
    transition: 0.5s ease;
    z-index: 2;
    background-color: #111;
    visibility: visible;
    opacity: 1;
    transition: all .8s ease;

    &.unvisible {
      visibility: hidden;
      opacity: 0;

    }

    @media (max-width: 767px) {
      right: 50%;
      border-radius: 20px;
      transform: translate(50%, 0);
      transition: 1s ease;
      width: 190px;
      top: 65px;
    }

    @media (min-width: 768px) and (max-width: 1340px) {
      right: 10px;
      width: 240px !important;

      @media (min-height: 950px) {
        top: 20px !important;
        width: 300px !important;
      }
    }

    @media (max-height: 500px) {
      @media (max-width: 992px) {
        top: 80px;
      }
    }

    @media (min-height: 501px) {
      top: 80px;
    }

    @media (min-height: 880px) and (min-width: 1340px) {
      top: 30px;
      width: 290px;

      @media (min-height: 900px) {
        top: 20px;
        width: 300px;
      }
    }

    @media (min-height: 768px) and (max-height: 860px) {
      @media (min-width: 768) {
        width: 245px;
        top: 80px;
      }

      @media (min-width: 1289px) and (orientation: portrait) {
        top: 25px;
        width: 300px;
      }

      @media (max-width: 400px) {
        top: 60px;
        width: 190px;
      }
    }

    @media (min-height: 1200px) {
      width: 250px;

      @media (min-width: 912px) {
        top: -30px;
        width: 300px !important;
      }
    }
  }
}
