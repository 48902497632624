.modal {
  background: rgba(0, 0, 0, 0.85);
  color: $black;
  height: 100%;
  min-height: 100%;
  opacity: 0;
  overflow: auto;
  padding: 0 1rem;
  pointer-events: none;
  position: relative;
  transition: opacity 238ms ease;
  visibility: hidden;
  width: 100%;
  z-index: 5000;

  @media (max-width: 560px) {
    padding: 0 0.75rem;
  }

  &.js-show_modal {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}

.modal-container {
  background: $white;
  border-radius: 28px;
  margin: 30px auto;
  max-width: 1180px;
  padding: 5.61rem;
  position: relative;
  width: 100%;
  z-index: 10;

  @media (max-width: 1040px) {
    padding: 4.209rem 2.369rem;
  }

  @media (max-width: 560px) {
    margin: 3.157rem auto;
  }
}

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 2.369rem;
  top: 2.369rem;

  @media (max-width: 1040px) {
    right: 1rem;
    top: 1rem;
  }

  & > svg {
    height: 1.777rem;
    transform: rotate(45deg);
    width: 1.777rem;

    & > path {
      fill: $black;
      transition: 100ms ease;
    }
  }

  &:hover {
    & > svg path {
      fill: $grey;
    }
  }
}

.modal-title {
  font-size: 2.369rem;
}

.modal-features {
  display: flex;
  flex-wrap: wrap;
  margin: -1.579rem -0.889rem;
  padding-top: 0;

  @media (max-width: 560px) {
    margin: -0.5rem 0;
    padding-top: 0;
  }
}

.modal-features-feature {
  flex-basis: 33.333%;
  padding: 1.579rem 0.889rem;

  @media (max-width: 800px) {
    flex-basis: 50%;
  }

  @media (max-width: 560px) {
    flex-basis: 100%;
    padding: 0.5rem 0;
  }
}

.modal-features-feature-icon {
  height: 3.157rem;
  width: auto;
}

.modal-features-feature-title {
  font-size: 1rem;
}

.modal-features-feature-description {
  color: $dark-grey;
  font-size: 0.8rem;
  margin-top: 0.317rem;
}

.floating.in {
  .floating-background {
    opacity: 1;
  }
  .floating-container {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.floating.hidden {
  display: none;
}

.floating {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: auto;

  .floating-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .floating-container {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    position: relative;
    border-radius: 0;
    z-index: 3;
    width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    left: 0px;
    margin: auto;
    right: 0px;
    top: 0;
    opacity: 0;
    transform: translate(0, 100px);
    transition: all 0.5s ease;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .floating-close {
    cursor: pointer;
    position: absolute;
    background-color: transparent;
    border: none;
    font-weight: bold;
    right: 10px;
    padding: 10px;
    top: 10px;
    font-size: 14pt;
  }

  .floating-body {
    max-width: 340px;
    margin: auto;
  }
  .step {
    margin-top: 20px;
    margin-bottom: 20px;
    .step-title {
      margin-bottom: 6px;
      font-weight: bold;
    }

    .step-text {
      margin-bottom: 8px;
      font-size: 10pt;
      line-height: 150%;
      font-family: sans-serif;
    }

    .step-small {
      margin-bottom: 8px;
      font-size: 8pt;
      font-style: italic;
      line-height: 100%;
      font-family: sans-serif;
    }

    .step-event-icon {
      height: 50px;
      margin: auto;
      margin-bottom: 10px;
      background-color: #eee;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 15px;
      height: 60px;
      width: 60px;
    }
  }

  .qr-code-container {
    margin-top:20px;
    width: 256px;
    text-align: center;
    position: relative;
    img {
      width: 100%;
    }
  }

  .step-separator {
    width: 80%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #aaa;
  }

  .download-cta-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .c_button {
      border:1px solid #ccc;
    }
  }
}
