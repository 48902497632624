.cookie-banner {
  background-color: rgba(255,255,255,0.95);
  
  color: #000000;
  font-size: 0.75rem;
  left: 0;
  right: 0;
  width: 80%;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  padding: 1rem 2.369rem;
  position: fixed;
  z-index: 99999;
  display: none;

  @media (min-width: 768px) {
    bottom:20px;
  }

  @media (max-width: 767px) {
    padding: 1rem;
    top: 100px;
  }
}

.cookie-banner-container {
  text-align: center;
  max-width: 860px;
  margin: auto;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  a {
    
    text-decoration: underline;
  }

  & .close-btn {
    flex-wrap: nowrap;
    margin-left: 1.777rem;

    @media (max-width: 800px) {
      margin-left: 0;
      margin-top: 0.563rem;
    }
  }

  .c_button {
    border-color: #000;
    color:#000;
    margin-top: 10px;
  }
}
