
.faqs-container {
    margin:auto;
    padding-top:20px;
    padding-bottom: 40px;
    max-width: 900px;
  
    .faqs-title {
      text-align: center;
      color: #fff;
      margin-bottom: 40px;
      
    }

    .c-faq-fieature-tag-container {
      position: relative;
      margin-bottom:-15px;
      top: 2px;
      left:5px;
      .c-faq-fieature-tag {
        display: inline-block;
        font-size:9pt;
        text-transform: uppercase;
        font-family: "Montserrat";
        font-weight: bold;
        padding:2px;
        border-radius: 4px;
        color:#fff;
        bottom: -20px;
        padding-left: 10px;
        padding-right:10px;

        @media screen and (max-width: 767px) {
          font-size: 8pt;
          padding:2px;
          padding-left: 6px;
          padding-right:6px;
        }
        
      }
    }
    
    .c-faq-feature-container {
      border-bottom: 1px solid #444;
      padding-top:0px;
      position: relative;
      padding-bottom: 0px;
    }
  
    .c-faq-feature {
      cursor: pointer;
      position: relative;
      border:none;
      width: 100%;
      text-align: left;
      background-color: transparent;
      padding-top:20px;
      padding-bottom:20px;
    }
  
    .c-faq-feature-container:hover {
      .c-faq-feature-open {
        transform:  translate(0,-50%) rotate(90deg);
        transform-origin: center;
      }
    }
  
    .c-faq-feature-title {
      font-family: "Montserrat", sans-serif;
      font-size: 20pt;
      font-weight: 700;
      margin:0;
      color:#fff;
      padding-right: 30px;
  
      @media screen and (max-width: 767px) {
        font-size: 13pt;
      }
    }

    a {
        color: dodgerblue;
    }

    p {
        padding-bottom: 10px;
    }
  
    .c-faq-feature-description {
      overflow: hidden;
      height: 0;
      margin:0;
      line-height: 180%;
      font-size: 12pt;
      font-weight: 300;
      font-family: sans-serif;
  
      @media screen and (max-width: 767px) {
        font-size: 10pt;
      }
      
      
      .c-faq-feature-description-text {
        margin-top:0px;
        padding-bottom:20px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  
    .c-faq-feature-container.opened {
      .c-faq-feature-description {
        height: auto;
      }
  
      .c-faq-feature-open {
        transform:  translate(0,-50%) rotate(45deg) scale(1.5);
      }
    }
  
    .c-faq-feature-open, .c-faq-feature-close {
      position: absolute;
      font-family: sans-serif;
      font-size: 18pt;
      font-weight: bold;
      transition: all .3s ease;
      right:10px;
      top:50%;
      color:#fff;
      transform: translate(0,-50%);
    }
  }
  