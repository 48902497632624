.header {
  left: 0;
  padding: 1rem 0 1rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-container {
  margin: auto;
  max-width: 1180px;
  width: 100%;
  position: relative;
  z-index: 10;
  @media (max-width: 767px) {
    text-align: left;
  }

}

.header-logo {
  display: inline-block;

  & > img {
    height: 2.369rem;

    @media (max-width: 767px) {
      height: 2.2rem;
    }
  }
}

.header-slice {
  position: absolute;
  background:#fff;		
  top:0;
  height: 1000px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
  left: -10%;
  margin-top: 10px;
  transform: translate(0%, -100%) rotate(-12deg);
  z-index: 1;
  @media screen and (max-width: 1200px) {
    top:-240px;
    height: 240px;
    width: 1000px;
    margin-top: 0px;
    transform: translate(0%, 0) rotate(-12deg);
    left: -40px;
  }
  @media screen and (max-width: 767px) {
    top:-250px;
    height: 240px;
    width: 1000px;
    left: -40px;
  }
}

