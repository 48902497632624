.c-text-feature {
  flex: 1;
}

.c-text-feature-title {
  display: inline-block;
  font-family: $roboto;
  font-size: 1.333rem;
  font-weight: 600;
  position: relative;

  &::after {
    background-color: #b0f4d1;
    clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0% 100%);
    content: "";
    height: 1rem;
    left: 0;
    position: absolute;
    top: 0.563rem;
    width: 170px;
    z-index: -1;
  }
}

.c-text-feature-description {
  color: $dark-grey;
  font-size: 0.8rem;
  margin-top: 0.563rem;
}
